
import { AuthState } from '@okta/okta-auth-js';
import { Server } from '../../Shared/Util/Server';
import { IAnalyticsRequestParams } from '../container/AnalyticsPage';
import { formatRequestParams } from './RequestFormatting';

export function fetchAnalytics(auth: AuthState, requestParams: IAnalyticsRequestParams): Promise<Api.IAnalyticsResponse | null> {
  const updatedParams = formatRequestParams(requestParams);
  return Server.get<Api.IAnalyticsResponse>(`/Response/search/nls?pageLimit=${updatedParams.pageSize}&pageOffset=${updatedParams.skip}&orderBy=${updatedParams.orderBy}${updatedParams.filter.filters ? `&filter=${updatedParams.filter.filters}` : ''}`, auth);
}

export function fetchAnalyticsCSV(auth: AuthState, requestParams: IAnalyticsRequestParams, columnList: string): Promise<string | null> {
  const updatedParams = formatRequestParams(requestParams);
  return Server.get<string>(`/Response/search/nls?fieldsJson=${columnList}&orderBy=${updatedParams.orderBy}${updatedParams.filter.filters ? `&filter=${updatedParams.filter.filters}` : ''}`, auth, 'text/csv');
}

export function fetchAnalyticsFacet(auth: AuthState, propertyName: string): Promise<Api.IAnalyticsResponse | null> {
  return Server.get<Api.IAnalyticsResponse>(`/Response/search/nls?pageLimit=1&facet=${propertyName},count:200`, auth);
}
