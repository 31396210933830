// IMPORT PACKAGE REFERENCES
import { AuthState} from "@okta/okta-auth-js";
import { withOktaAuth } from "@okta/okta-react";
import * as React from "react";
import { connect } from "react-redux";
import { Route,  Switch } from "react-router";
import { compose, Dispatch } from "redux";
import { Routes } from "../../AppConstants";
import { validateAuthState } from "../../Auth/AuthHelper";
import { DraftResponses } from "../../DraftResponses/Index";
import i18n from "../../i18n";
import { IStoreState } from "../../Model";
import { Responses } from "../../Responses/Index";
import { Error } from "../../Shared/Error";
import { LoadingIndicator } from "../../Shared/LoadingIndicator";
import { ShortenNumber } from "../../Shared/Util/NumberFormatter";
import { Update } from "../../Updates/Index";
import { Tile } from "../components/Tile/Tile";
import { TileStrip } from "../components/TileStrip/TileStrip";
import { getSummaryThunk } from "../duck/Actions";
import '../../../styles/app.scss';
import { WithOktaAuthProps } from "src/ts/Auth/duck/Models";

// COMPONENT
export interface IHomeStateProps {
  failed: boolean;
  fetched: boolean;
  fetching: boolean;
  updateCount: number;
  responseCount: number;
  draftResponseCount: number;
}

interface IHomePageDispatchProps {
  fetchSummary: (auth: AuthState | null) => any;
}

interface IHomePageOwnProps { currentPage: number, activeRoute: string
}

export type IHomeProps = IHomeStateProps & IHomePageDispatchProps & IHomePageOwnProps & WithOktaAuthProps;

class HomePage extends React.Component<IHomeProps> {

  constructor(props: IHomeProps) {
    super(props);
    this.props.oktaAuth.authStateManager.updateAuthState();
  }

  public componentDidMount() {
    if (this.props.authState?.isAuthenticated) {
      this.props.fetchSummary(this.props.authState);
    }
    else {
      validateAuthState(this.props.authState);
    }
  }

  public componentDidUpdate(prevProps: IHomeProps) {
    if (prevProps.activeRoute !== this.props.activeRoute) {
      this.props.fetchSummary(this.props.authState);
    }
    else if (prevProps.authState === null && this.props.authState?.isAuthenticated) {
      this.props.fetchSummary(this.props.authState);
    }
    else {
      validateAuthState(this.props.authState);
    }
  }

  public render() {
    if (!this.props.authState) {
      return <LoadingIndicator busy={true} />
    }
    if (this.props.authState.error) {
      throw this.props.authState.error;
    }
    return (
      <main>
        <div className="dashboard">
          <LoadingIndicator busy={this.props.fetching} />
          {this.props.failed && <Error message={i18n.t("home.fetchingUpdatesFailed")} />}
          <Switch>
            <Route path={`${Routes.updateList}/:pageNumber`} component={Update} />
            <Route path={`${Routes.responseList}/:pageNumber`} component={Responses} />
            <Route path={`${Routes.draftResponseList}/:pageNumber`} component={DraftResponses} />
          </Switch>
        </div>
        <TileStrip>
          <Tile heading={i18n.t("home.tiles.updates")}
            route={Routes.updateList}
            count={ShortenNumber(this.props.updateCount)}
            subtitle="" />
          <Tile heading={i18n.t("home.tiles.draftResponses")}
            route={Routes.draftResponseList}
            count={ShortenNumber(this.props.draftResponseCount)}
            subtitle="" />
          <Tile heading={i18n.t("home.tiles.responses")}
            route={Routes.responseList}
            count={ShortenNumber(this.props.responseCount)}
            subtitle="" />
        </TileStrip>
        
      </main>
    );
  }
};

const mapStateToProps = (state: IStoreState) => {
  return {
    failed: state.home.failed,
    fetched: state.home.fetched,
    fetching: state.home.fetching,
    error: state.home.error,
    updateCount: state.home.updateCount,
    responseCount: state.home.responseCount,
    draftResponseCount: state.home.draftResponseCount
  }
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    fetchSummary: (auth: AuthState) => {
      dispatch(getSummaryThunk(auth));
    }
  };
};

const hoc = compose(withOktaAuth, connect<IHomeStateProps, IHomePageDispatchProps, IHomePageOwnProps>(mapStateToProps, mapDispatchToProps))(HomePage);


export { hoc as HomePage };
