import { Action, Dispatch } from "redux";
import { fetchResponses } from "./Operations";
import { AuthState } from "@okta/okta-auth-js";
import { Action as AnyAction } from "@reduxjs/toolkit";

export enum ResponseActionTypes {
  FetchResponsesPending = "FETCH_RESPONSES_PENDING",
  FetchedResponses = "FETCHED_RESPONSES",
  FetchResponsesRejected = "FETCH_RESPONSES_REJECTED"
}

export class FetchingResponsesAction implements Action {
  public readonly type = ResponseActionTypes.FetchResponsesPending;
}

export class FetchedResponsesAction implements Action {
  public readonly type = ResponseActionTypes.FetchedResponses;
  constructor(
    public payload: { responses: Api.IPagedResult<Api.IResponseListViewModel> }
  ) {}
}

export class FetchResponsesFailedAction implements Action {
  public readonly type = ResponseActionTypes.FetchResponsesRejected;
  constructor(public payload: { error: string }) {}
}

export type ResponsesAction =
  | FetchingResponsesAction
  | FetchedResponsesAction
  | FetchResponsesFailedAction;

export const getResponseListThunk: (
  pageNumber: number,
  itemPerPage: number,
  sortOrder: string,
  auth: AuthState,
  searchKeyword?: string
) => any = (pageNumber, itemPerPage, sortOrder, auth, searchKeyword) => {
  return (dispatch: Dispatch<AnyAction>) => {
    dispatch(new FetchingResponsesAction());
    fetchResponses(pageNumber, itemPerPage, sortOrder, auth, searchKeyword)
      .then((response: Api.IResponseListResponse) => {
        dispatch(new FetchedResponsesAction({ responses: response.responses }));
      })
      .catch((errorResponse: Error) => {
        // tslint:disable-next-line:no-console
        console.error(errorResponse);
        dispatch(
          new FetchResponsesFailedAction({ error: errorResponse.message })
        );
      });
  };
};
