import * as classNames from "classnames";
import * as React from "react";
import { FieldArray, WrappedFieldArrayProps } from "redux-form";
import i18n from "../../../i18n";

interface IChannelProps {
  channelKey: string;
  index: number;
  channels: any;
  removeChannel: (
    channel: Api.IChannelViewModel,
    index: number,
    channels: any
  ) => () => any;
}

const renderChannels = (props: IChannelProps) => {
  const channel = props.channels.get(props.index);
  return (
    <div className="channel" key={props.index}>
      <span className="channel-name">{channel.name}</span>
      <i
        data-testid={`channel-remove-${props.index}`}
        className="icon icon-close"
        onClick={props.removeChannel(channel, props.index, props.channels)}
      />
    </div>
  );
};

interface IChannelSelectionProps extends WrappedFieldArrayProps<any> {
  availableChannels: Api.IChannelViewModel[];
  handleAddChannel: (channel: Api.IChannelViewModel, fields: any) => () => any;
  handleRemoveChannel: (
    channel: Api.IChannelViewModel,
    index: number,
    fields: any
  ) => () => any;
}

const renderChannelSection = (props: IChannelSelectionProps) => {
  return (
    <React.Fragment>
      <div className="dropdown">
        <div className="selected-channels">
          <button
            className={classNames("btn", "btn-outline-secondary")}
            style={{"border": props.meta.error ? "1px solid #DC3545" : "1px solid #BFC6CE"}}
            type="button"
            id="dropdownMenuButton"
            data-bs-toggle="dropdown"
            disabled={!props.availableChannels.length}
            aria-haspopup="true"
            aria-expanded="false"
          >
            {getAddChannelButtonText(props.availableChannels.length)}
          </button>
          <div className={`dropdown-menu${!props.availableChannels?.length ? 'hide' : ''}`} aria-labelledby="dropdownMenuButton">
            {props.availableChannels.map((x, index) => (
              <button
                key={x.id}
                className="dropdown-item"
                onClick={props.handleAddChannel(x, props.fields)}
                type="button"
              >
                {x.name}
              </button>
            ))}
          </div>
          {props.fields &&
            props.fields.map((member, index, channels) => {
              return renderChannels({
                channelKey: member,
                index,
                channels,
                removeChannel: props.handleRemoveChannel,
              });
            })}
        </div>{" "}
        {props.meta.error && (
          <div className={`validation-error invalid`}>
            {i18n.t("manage.responseVariations.atLeastOneChannelMsg")}
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

const getAddChannelButtonText = (len: number) => {
  if (len > 0) {
    return (
      <React.Fragment>
        {i18n.t("manage.responseVariations.addChannel")}
        <i className="fa fa-chevron-down" />
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        {i18n.t("manage.responseVariations.allChannelsUsed")}
      </React.Fragment>
    );
  }
};

export interface IChannelsProps {
  responseVariation: string;
  availableChannels: Api.IChannelViewModel[];
  addChannel: (
    channel: Api.IChannelViewModel,
    variationKey: string
  ) => () => any;
  removeChannel: (
    channel: Api.IChannelViewModel,
    variationKey: string
  ) => () => any;
}

class Channels extends React.Component<IChannelsProps> {
  constructor(props: IChannelsProps) {
    super(props);

    this.handleAddChannel = this.handleAddChannel.bind(this);
    this.handleRemoveChannel = this.handleRemoveChannel.bind(this);
  }

  public render() {
    return (
      <FieldArray
        name={`${this.props.responseVariation}.channels`}
        component={renderChannelSection}
        props={{
          availableChannels: this.props.availableChannels,
          handleAddChannel: this.handleAddChannel,
          handleRemoveChannel: this.handleRemoveChannel,
        }}
      />
    );
  }

  private handleAddChannel(channel: Api.IChannelViewModel, fields: any) {
    const parent = this;
    return () => {
      parent.props.addChannel(channel, this.props.responseVariation);
      fields.push(channel);
    };
  }

  private handleRemoveChannel(
    channel: Api.IChannelViewModel,
    index: number,
    fields: any
  ) {
    const parent = this;
    return () => {
      parent.props.removeChannel(channel, this.props.responseVariation);
      fields.remove(index);
    };
  }
}

export { Channels };
