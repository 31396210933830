import { AuthState } from "@okta/okta-auth-js";
import { Action, Dispatch } from "redux";
import { fetchDrafts } from "./Operations";
import { Action as AnyAction } from "@reduxjs/toolkit";

export enum DraftActionTypes {
  FetchDraftsPending = "FETCH_DRAFTS_PENDING",
  FetchedDrafts = "FETCHED_DRAFTS",
  FetchDraftsRejected = "FETCH_DRAFTS_REJECTED",
}

export class FetchingDraftsAction implements Action {
  public readonly type = DraftActionTypes.FetchDraftsPending;
}

export class FetchedDraftsAction implements Action {
  public readonly type = DraftActionTypes.FetchedDrafts;
  constructor(public payload: { drafts: Api.IPagedResult<Api.IResponseListViewModel> }) {  }
}

export class FetchDraftsFailedAction implements Action {
  public readonly type = DraftActionTypes.FetchDraftsRejected;
  constructor(public payload: { error: string }) {  }
}

export type DraftsAction = FetchingDraftsAction
| FetchedDraftsAction
| FetchDraftsFailedAction;


export const getDraftListThunk: (pageNumber: number, itemPerPage: number, sortOrder: string, auth: AuthState) => any = (pageNumber, itemPerPage, sortOrder, auth) => {
  return (dispatch: Dispatch<AnyAction>) => {
    dispatch(new FetchingDraftsAction());
    return fetchDrafts(pageNumber, itemPerPage, sortOrder, auth)
      .then((response: Api.IDraftResponseListResponse) => {
        dispatch(new FetchedDraftsAction({ drafts: response.drafts }));
      })
      .catch((errorResponse: Error) => {
        // tslint:disable-next-line:no-console
        console.error(errorResponse);
        dispatch(new FetchDraftsFailedAction({ error: errorResponse.message }));
      });
  }
}
