import { Button, Card, IconButton, Popover, Textfield, Typography } from '@panviva/panviva-react-ui';
import { useEffect, useState } from 'react';
import './FilterStyles.scss';

export const NumberFilter = (props: any) => {

    const { field, firstFilterValue, secondFilterValue, onFilterApply, onClear } = props;

    const [fromValue, setFromValue] = useState(firstFilterValue);
    const [toValue, setToValue] = useState(secondFilterValue);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [open, setOpen] = useState(false);
    const id = open ? 'popper-numeric' : undefined;

    useEffect(()=>{
        setOpen(Boolean(anchorEl))
    },[anchorEl])

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(anchorEl ? null : event.currentTarget?.parentElement);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const onFirstFilterChange = (event: any) => {
        setFromValue(parseInt(event.target.value));
    };

    const onSecondFilterChange = (event: any) => {
        setToValue(parseInt(event.target.value));
    };

    const onButtonClick = (buttonName: string) => {
        if (buttonName === 'apply') {
            const obj = {from: {value: fromValue, operator: 'ge'}, to: {value: toValue, operator:'le'}}
            onFilterApply(field.property, obj, "eq", true);
        } else {
            onClear(field.property); setFromValue(''); setToValue('');
        }
        setAnchorEl(null);
    };

    return (
        <>
            <IconButton icon={(firstFilterValue >= 0 || secondFilterValue >= 0) ? "filter-active" : "filter-inactive"} onClick={handleClick}/>

            <Popover id={id} open={open} anchorEl={anchorEl} onClose={() => handleClose()} anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}>
                <Card style={{ display: 'flex' }}>
                    <Typography
                        className='filter-title'
                        variant="body_2"
                    >
                        {field.name}
                    </Typography>
                    <Textfield
                        id="from"
                        value={fromValue}
                        name="From"
                        onChange={onFirstFilterChange}
                        label="From"
                        type="number"
                        min={"0"}
                    />
                    <Textfield
                        id="to"
                        value={toValue}
                        name="To"
                        onChange={onSecondFilterChange}
                        label="To"
                        type="number"
                        min={"0"}
                    />
                    <div className='filter-button'>
                        <Button variant='text' onClick={() => onButtonClick('clear')}>clear</Button>&nbsp;
                        <Button variant='text' onClick={() => onButtonClick('apply')} disabled={fromValue < 0 || toValue < 0}>apply</Button>
                    </div>
                </Card>
            </Popover>
        </>
    );
};
