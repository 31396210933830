import * as qs from 'qs';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { InvalidIntegerRedirector } from '../Shared/Util/InvalidIntegerRedirector';
import { ManageResponsePage } from './container/ManageResponsePage';

interface IManagePageProps extends RouteComponentProps<any> {
  responseId: string;
}

export const ManageResponse: React.FC<IManagePageProps> = (props: IManagePageProps) => {
  const searchParams = qs.parse(props.location.search, { ignoreQueryPrefix: true });
  return (
    <InvalidIntegerRedirector numberStrings={[searchParams.documentId] as string[]}>
      <ManageResponsePage
        {...{
          id: props.match.params.responseId,
          documentId: parseInt(searchParams.documentId as string, 10),
          isDraft: searchParams.isDraft && (searchParams.isDraft as string).toLowerCase() === 'true'
        }} />
    </InvalidIntegerRedirector>
  )
};
