// IMPORT PACKAGE REFERENCES

import { Action, Store, applyMiddleware } from 'redux';

// IMPORT MIDDLEWARE

import promise from 'redux-promise-middleware';
import { thunk } from 'redux-thunk';

// IMPORT REDUCERS

import { persistStore, 
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER } from 'redux-persist';
import AppReducer from './AppReducer';
import { actionToPlainObject } from './Middleware/actionToPlainObject';
import { IStoreState } from './Model';
import { configureStore } from '@reduxjs/toolkit';
import { composeWithDevTools } from '@redux-devtools/extension';


// CONFIGURE STORE
const middlewareList = [thunk, promise, actionToPlainObject];
export const middleware = composeWithDevTools(applyMiddleware(...middlewareList));

export const store : Store<IStoreState, Action> = configureStore({
  reducer: AppReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }).concat(...middlewareList),
  devTools:  (process.env.NODE_ENV !== 'production')? false : true
})

export const persistor = persistStore(store);
