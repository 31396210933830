// IMPORT PACKAGE REFERENCES
import * as React from "react";
import * as ReactDOM from "react-dom";

import './LoadingIndicator.scss';

// COMPONENT
interface ILoadingProps {
  busy: boolean;
  message?: string | null
}


const LoadingIndicator = (props: ILoadingProps) => {
  const container = document.getElementById("loading-indicator");
  if (!container) {
    throw new Error('Make sure the loading indicator container exist alongside root div');
  }

  return (
    <React.Fragment>
      {ReactDOM.createPortal(
        props.busy && <div className="showbox">
          <h1>{props.message}</h1>
          <div className="loader">
            <svg className="circular" viewBox="25 25 50 50">
              <circle
                className="path"
                cx="50"
                cy="50"
                r="20"
                fill="none"
                strokeWidth="4"
                strokeMiterlimit="10"
              />
            </svg>
          </div>

        </div>,
        container as Element
      )}
    </React.Fragment>
  );
};

// EXPORT COMPONENT

export { LoadingIndicator };
