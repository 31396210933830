// IMPORT PACKAGE REFERENCES
import * as React from 'react';

export interface IErrorProps {
    message: string;
}

// COMPONENT

const Error: React.FC<IErrorProps> = (props: IErrorProps) => (
    <div className="error-message">
      <div className="alert alert-danger" role="alert">
          <i className="icon icon-info fa-2x" />
          <span className="h4 ml-3 alert-heading">Unexpected Application Error</span>
          <p className="ml-5 mt-3">{props.message}</p>
      </div>
    </div>
);

export { Error };
