import update from "immutability-helper";
import { ResponseActionTypes, ResponsesAction } from "./Actions";
import { IResponsesState } from "./Models";

const initialState: IResponsesState = {
  failed: false,
  fetched: false,
  fetching: false,
  error: '',
  responses: {
    firstRowOnPage: 0,
    lastRowOnPage: 10,
    pageCount: 0,
    pageSize: 10,
    rowCount: 0,
    currentPage: 1,
    sortOrder: '',
    results: [],
    searchKeyword: ''
  }
};

export const ResponsesReducer = (
  state = initialState,
  action: ResponsesAction
): IResponsesState => {
  switch (action.type) {
    case ResponseActionTypes.FetchResponsesPending:
      return update(state, {
        fetched: { $set: false },
        fetching: { $set: true },
        failed: { $set: false },
        error: { $set: '' }
      });
    case ResponseActionTypes.FetchResponsesRejected:
      return update(state, {
        responses: { $set: { ...initialState.responses } },
        error: { $set: action.payload.error },
        fetched: { $set: false },
        fetching: { $set: false },
        failed: { $set: true }
      });
    case ResponseActionTypes.FetchedResponses:
      return update(state, {
        fetched: { $set: true },
        fetching: { $set: false },
        failed: { $set: false },
        error: { $set: '' },
        responses: { $set: { ...action.payload.responses } }
      });
    default:
      return state;
  }
}
