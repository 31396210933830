import { AuthState } from "@okta/okta-auth-js";
import { withOktaAuth } from "@okta/okta-react";
import * as React from "react";
import { connect } from "react-redux";
import { compose, Dispatch } from "redux";
import { responseListDefaultSort, Routes } from "../../AppConstants";
import { validateAuthState } from "../../Auth/AuthHelper";
import i18n from "../../i18n";
import { IStoreState } from "../../Model";
import { Error } from "../../Shared/Error";
import { LoadingIndicator } from "../../Shared/LoadingIndicator";
import { Responses } from "../../Shared/ResponseTable/ResponseList";
import { DORCTooltip } from "../../Shared/Tooltip";
import { getDraftListThunk } from "../duck/Actions";
import './DraftListPage.scss';
import { WithOktaAuthProps } from "src/ts/Auth/duck/Models";

export interface IDraftsStateProps {
  failed: boolean;
  fetched: boolean;
  fetching: boolean;
  drafts: Api.IPagedResult<Api.IResponseListViewModel>;
}

interface IDraftsPageDispatchProps {
  fetchDraftList: (pageNumber: number, itemPerPage: number, sortOrder: string, auth: AuthState | null) => any;
}

interface IDraftsPageOwnProps {
  currentPage: number, sortOrder: string ;
}

type IDraftsProps = IDraftsStateProps & IDraftsPageDispatchProps & IDraftsPageOwnProps & WithOktaAuthProps;

class DraftListPage extends React.Component<IDraftsProps> {
  constructor(props: IDraftsProps) {
    super(props);
  }

  public componentDidMount() {
    validateAuthState(this.props.authState);
    this.props.fetchDraftList(this.props.currentPage, this.props.drafts.pageSize, `-${responseListDefaultSort}`, this.props.authState);
  }

  public componentDidUpdate(prevProps: IDraftsProps) {
    validateAuthState(this.props.authState);
    if (prevProps.currentPage !== this.props.currentPage
      || prevProps.sortOrder !== this.props.sortOrder
      || prevProps.drafts.sortOrder !== this.props.drafts.sortOrder) {
      this.props.fetchDraftList(this.props.currentPage, this.props.drafts.pageSize, this.props.sortOrder || `-${responseListDefaultSort}`, this.props.authState);
    }
  }

  public render() {
    return (
      <div className="draft-list">
        <div className='title-container'>
        <h1 className="title">{i18n.t("draftResponseList.heading")}</h1>
        <span className="guide">
          {i18n.t("draftResponseList.message")}
          <DORCTooltip bootstrapIcon="icon-info-circle" tooltipText={i18n.t("draftResponseList.tooltipText")} placement="right" type="info"
                         iconClass="msg-tooltip-icon" triggerEvent="click" tooltipClass="cc-tooltip"/>
        </span>
        </div>
        <div className='vertical-spacer'></div>
        
        {this.props.failed && <Error message={i18n.t("draftResponseList.fetchingResponsesFailed")} />}
        <LoadingIndicator busy={this.props.fetching}/>
        {this.props.fetched && <Responses baseRoute={Routes.draftResponseList}
          list={this.props.drafts}
          isDraftList={true}
          authState={this.props.authState}
          handlePageSizeChange={this.props.fetchDraftList} />}
      </div>
    );
  }
}

const mapStateToProps = (state: IStoreState) => {
  return {
    ...state.drafts
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    fetchDraftList: (pageNumber: number, itemPerPage: number, sortOrder: string, auth: AuthState) => {
      dispatch(getDraftListThunk(pageNumber, itemPerPage, sortOrder, auth));
    }
  };
};

const hoc = compose(withOktaAuth, connect<IDraftsStateProps, IDraftsPageDispatchProps, IDraftsPageOwnProps>(mapStateToProps, mapDispatchToProps))(DraftListPage);

export { hoc as DraftListPage };
