import * as moment from "moment";
import { Field, FieldArray } from "redux-form";
import i18n from "../../../i18n";
import { FormInput } from "../../../Shared/Util/FormInput";
import { nameof } from "../../../Shared/Util/NameOf";
import { IManageResponseFormValues, IMetadatum } from "../../duck/Models";
import { Tags } from "../Tags/Tags";
import { Urls } from "../Urls/Urls";

const generateNameProp = (index: number, isMultivalue: boolean) =>
  nameof<IManageResponseFormValues>("metadata")+`[${index}].values${isMultivalue?"":"[0]"}`;

const enum MetadataInputType {
  Tags = "tags",
  TextBox = "textbox",
  Number = "number",
  Url = "url",
  Date = "date"
}

const renderMetadata = (inputType: string, props: any) => {
  switch(inputType){
    case MetadataInputType.Tags :
      // https://github.com/DefinitelyTyped/DefinitelyTyped/issues/18279
      // @ts-ignore // This is a redux form issue so ignoring as it affects buils
      return <FieldArray component={Tags} {...props} />
    case MetadataInputType.Url :
      return <FieldArray component={Urls} {...props} />
    case MetadataInputType.Number :
      // Cannot use number type as invalid input results in empty string - https://stackoverflow.com/questions/48342454/handling-number-input-with-redux-form-does-strange-things
      return <div className="form-fields"> <Field type="text" inputMode="numeric" pattern="[-]?[0-9]*[\.]?[0-9]*" component={FormInput} {...props} validate={validateNumber} normalize={normaliseNumber}/> </div>
    case MetadataInputType.Date :
      return <div className="form-fields"> <Field type="date" component={FormInput} {...props} validate={validateDate} /> </div>
    default :
      return <div className="form-fields"> <Field component={FormInput} {...props} /> </div>;
  }
}

const validateDate = (input: string) => {
  const dateFormat = 'DD-MM-YYYY';
  const toDateFormat = moment(new Date(input)).format(dateFormat);
  // Skip if no value
  if (input === '' || input === undefined) {
    return undefined;
  }
  // check for valid date input
  return moment(toDateFormat, dateFormat, true).isValid() ? undefined : i18n.t('manage.metadata.invalidDate');
}


const normaliseNumber = (value: string, previousValue: string) => {
  return (value || "").replace(/[^\d\.\-]/g, "")
}

const validateNumber = (input: string) => {
  if (input === null || input === undefined || input.length === 0 )
  {
    return undefined;
  }
  let parsed = Number(input)
  return (isNaN(parsed) || parsed == Number.NEGATIVE_INFINITY || parsed == Number.POSITIVE_INFINITY)
    ? i18n.t('manage.metadata.invalidNumber') : undefined;
}

export const renderMetadataFields = ({id, inputControl, alias, tooltipText, getKeywords, primaryResponse, showWandIcon }: IMetadatum, index: number) => {
  const isMultivalue = (inputControl === "tags") || (inputControl === "url");
  const hasTooltip = tooltipText ? tooltipText.length > 0 : false;

  const generatedProps = {
    name: generateNameProp(index, isMultivalue),
    label: alias,
    tooltipText: hasTooltip ? tooltipText : undefined,
    uid: name,
    showWandIcon: alias == "Keywords" ? showWandIcon : false,
    getKeywords: getKeywords,
    primaryResponse: primaryResponse,
    disableWandIcon: !(primaryResponse? primaryResponse.trim():false),
    wandIconTooltipText: i18n.t("manage.tooltips.keywordWandiconTooltip")
  }

  return {
    index,
    "component": (
      <div key={"custom-prop-"+id}>{
        renderMetadata(inputControl, generatedProps)
      }</div>
    )}
}
