import { withOktaAuth } from '@okta/okta-react';
import * as React from 'react';
import { Routes } from '../AppConstants';
import appHistory from '../AppHistory';
import i18n from '../i18n';
import { LoadingIndicator } from '../Shared/LoadingIndicator';
import { WithOktaAuthProps } from './duck/Models';


class AuthPending extends React.Component<WithOktaAuthProps> {
  constructor(props: WithOktaAuthProps) {
    super(props);
  }

  public render() {
    if (!this.props.authState || !this.props.authState.isAuthenticated) {
      return <h1>{i18n.t("auth.loadingMessage")}</h1>
    }
    appHistory.push(Routes.notFound);
    return <LoadingIndicator busy={true} />
  }
}

export default withOktaAuth(AuthPending);
