import * as classNames from 'classnames';
import * as React from 'react';
import { Field, WrappedFieldArrayProps } from 'redux-form';
import i18n from '../../../i18n';
import { IPanvivaFieldProps } from '../../../Shared/Forms';
import { DORCTooltip } from '../../../Shared/Tooltip';
import { Channels } from './Channels';
import './Responsevariation.scss';
import { IGeneratedResponseVariations } from '../../duck/Models';
import { Tooltip } from 'react-tooltip';

export interface IResponseVariationProps extends WrappedFieldArrayProps<any> {
  variation: string;
  index: number;
  availableChannels: Api.IChannelViewModel[];
  removeVariation: (index: number) => () => any;
  addChannel: (channel: Api.IChannelViewModel, variationKey: string) => any;
  removeChannel: (channel: Api.IChannelViewModel, variationKey: string) => any;
  disabledAIAssistant: boolean;
  showAIAssistant: boolean;
  fetchResponseVariation: Function;
  generatedResponseVariation: IGeneratedResponseVariations | undefined;
  removeRefreshIndex?: (index: number) =>any ;
}

const renderContentField = ({ input, meta }: IPanvivaFieldProps) => {
  return (
    <div className={classNames('content', { 'is-invalid': meta.error })} >
      <textarea {...input} className={classNames('form-control')} rows={2}/>
      <div className="invalid-feedback">
        {meta.error}
      </div>
    </div>
  )
}

export const ResponseVariation = (props: IResponseVariationProps) => {

  const [shouldRefresh, setShouldRefresh] = React.useState<boolean>(false);


  let { channels } = props.fields.get(props.index);
  channels =  channels ? channels.map((channel: any, _: number) => channel.name) : [];

  const channelsEqual = function(generatedChannels: string[]){
    return channels.sort().toString() === generatedChannels.sort().toString();
  }

  React.useEffect(function(){
    const setRefresh  = function(){
      if(props.generatedResponseVariation && channelsEqual(props.generatedResponseVariation.channels)){
        setShouldRefresh(true);
        return;
      }
      setShouldRefresh(false);
    }
    setRefresh();
  }, [props.generatedResponseVariation, props.availableChannels])

  const isAiAssistantEnabled = () => {
    const isChannelsSelected = channels.length > 0;
    return props.disabledAIAssistant == false && isChannelsSelected;
  }
  return (

    <div className="response-variation-box" id={props.variation}>
      <div className="response-variation" key={props.index}>
        <div className="channels form-group">
          <div className="header">
            <span className="field-label">{i18n.t("manage.responseVariations.channels")}</span>
            <DORCTooltip bootstrapIcon="icon-info-circle" tooltipText={i18n.t("manage.responseVariations.responseVariationChannelsTooltip")} placement="right" type="info"
              iconClass="msg-tooltip-icon" triggerEvent="click" tooltipClass="cc-tooltip"/>
            <i className="icon components-icon-close" onClick={props.removeVariation(props.index)} />
          </div>
          <div className="channel-selection">
            <Channels addChannel={(channel: Api.IChannelViewModel, variationKey: string): any => {
              props.addChannel(channel, variationKey);
            }}
              availableChannels={props.availableChannels}
              removeChannel={(channel: Api.IChannelViewModel, variationKey: string): any => {
                props.removeChannel(channel, variationKey)
              }}
              responseVariation={props.variation} />
          </div>
        </div>

        <div className="variation-header">
          <div className="header">{i18n.t("manage.responseVariations.title")}
            {!shouldRefresh && props.showAIAssistant && isAiAssistantEnabled() &&
              <div className="wandicon" onClick={() => props.fetchResponseVariation(channels, props.index)}>
                <img src="/images/wandicon.svg" alt="wand icon"/>
              </div>
            }
            {!shouldRefresh && props.showAIAssistant && !isAiAssistantEnabled() &&
              <>
                <div className="wandicon disabled"
                     data-tooltip-id="response-variation-tooltip"
                     data-tooltip-content={i18n.t("manage.responseVariations.enableAiIconTooltip")}
                     >
                  <span className="msg-tooltip-icon">
                    <img src="/images/DisabledWandicon.svg" alt="wand icon"/>
                  </span>
                </div>
                <Tooltip id="response-variation-tooltip" place="right" variant="info" className="cc-tooltip" openOnClick={true} />
              </>
            }
            {
              shouldRefresh && props.showAIAssistant && isAiAssistantEnabled() &&
              <svg className='refresh-icon' width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"
                onClick={() => props.fetchResponseVariation(channels, props.index)}>
                <path d="m19 8 4 4h-3c0 4.42-3.58 8-8 8a7.93 7.93 0 0 1-3.984-1.072l-.276-.168L9.2 17.3a5.87 5.87 0 0 0 2.8.7 6.005 6.005 0 0 0 5.996-5.775L18 12h-3l4-4zm-7-4a7.93 7.93 0 0 1 3.984 1.072l.276.168L14.8 6.7A5.87 5.87 0 0 0 12 6a6.005 6.005 0 0 0-5.996 5.775L6 12h3l-4 4-4-4h3c0-4.42 3.58-8 8-8z" fill="#2574DB" fillRule="nonzero" />
              </svg>
            }
          </div>
        </div>

        <Field name={`${props.variation}.content`}
          type="textarea"
          onChange={(e: any)=> {
            if(e.target.value === ""  && shouldRefresh && props.removeRefreshIndex){
              props.removeRefreshIndex(props.index);
            }
          }}
          component={renderContentField} />

      </div>

    </div>
  )
}

