import * as React from "react";
import './Shared.scss'

interface ISkeletonTableRowProps {
  numOfColumns: number;
}

export const SkeletonTableRow: React.FC<ISkeletonTableRowProps> = (props) => (
  <tr className="skeleton-table-row">
    { [...Array(props.numOfColumns)].map((item, i) => <td key={i}> <div className="skeleton-table-cell" /> </td>) }
  </tr>
);
