import * as React from 'react';
import { Redirect } from 'react-router';
import { Routes } from '../../AppConstants';

export const InvalidIntegerRedirector: React.FC<React.PropsWithChildren<{ numberStrings: string[] }>> = (props) => {
  const isInvalid = props.numberStrings.some(numString => {
    if(numString) {
      return isNaN(parseInt(numString, 10));
    }
    return false;
  });

  if (isInvalid) {
    return (
      <Redirect to={Routes.notFound} />
    );
  } else {
    return (
      <React.Fragment>
        {props.children}
      </React.Fragment>
    );
  }
}
