import * as classNames from "classnames";
import * as React from "react";
import { Fragment } from "react";
import { Field, FieldArray, WrappedFieldArrayProps } from "redux-form";
import i18n from "../../../i18n";
import { IPanvivaFieldProps } from "../../../Shared/Forms";
import './QueryVariations.scss'

type QueryVariationsProps = IPanvivaFieldProps & {
  showWandIcon: boolean;
  onRefresh: (numberto: number, index: number) => any;
};

interface AlternateQueryFieldGroup extends WrappedFieldArrayProps<any> {
  showWandIcon: boolean;
  onRefresh: (numberto: number, index: number) => any;
}

class AlternateQueryFieldGroup extends React.Component<AlternateQueryFieldGroup> {
  constructor(props: AlternateQueryFieldGroup) {
    super(props);
    this.removeQuery = this.removeQuery.bind(this);
    this.addQuery = this.addQuery.bind(this);

    if (props.fields.length === 0) {
      this.props.fields.push("");
    }
  }

  public componentDidUpdate(prevProps: any) {
    if (this.props.fields.length === 0) {
      this.props.fields.push("");
    }
  }

  public render() {
    return (
      <div className="query-variations">
        {this.props.fields.map((query: any, index: any) => (
          <AlternateQueryField
            query={query}
            index={index}
            key={index}
            onRemove={this.removeQuery}
            onRefresh={this.props.onRefresh}
            isLast={index === this.props.fields.length - 1}
            onAddQuery={this.addQuery(index)}
            showWandIcon={this.props.showWandIcon}
          />
        ))}
      </div>
    );
  }

  private removeQuery(index: any) {
    this.props.fields.remove(index);
  }

  private addQuery = (index: number) => () => {
    if (this.props.fields.get(index)) {
      let length = this.props.fields.length;
      let x = 0;
      for (let i = 0; i < length - 1; i++) {
        if (this.props.fields.get(i) == this.props.fields.get(index)) {
          x = 1;
          break;
        }
      }
      if (x == 1) {
        this.props.fields.remove(index);
      }
      this.props.fields.push("");
    }
  }
}

class AlternateQueryField extends React.Component<{
  query: any;
  index: any;
  onRemove: (index: any) => any;
  onRefresh: (numberto: number, index: number) => any;
  isLast: boolean;
  onAddQuery: () => any;
  showWandIcon: boolean;
}> {
  constructor(props: any) {
    super(props);
    this.onRemove = this.onRemove.bind(this);
    this.renderField = this.renderField.bind(this);
    this.onRefresh = this.onRefresh.bind(this);
  }

  public render() {
    return (
      <Field
        name={this.props.query}
        type="text"
        component={this.renderField}
        props={{ isLast: this.props.isLast, showWandIcon: this.props.showWandIcon }}
      />
    );
  }

  private onRemove() {
    this.props.onRemove(this.props.index);
  }

  private onRefresh() {
    this.props.onRefresh(1, this.props.index);
  }

  private renderField(fieldProps: IPanvivaFieldProps & { isLast: boolean ,showWandIcon: boolean }) {
    return (
      <div className="input-group" key={this.props.index}>
        <input
          {...fieldProps.input}
          type={fieldProps.type}
          placeholder={i18n.t("manage.queryVariations.inputPlaceHolder")}
          className={classNames("form-control", { last: fieldProps.isLast })}
        />
        {
          fieldProps.input.value && !fieldProps.isLast && fieldProps.showWandIcon ?
            <div className="input-group-append top-bottom-border">
              <img className="refresh refresh-icon-button" src="/images/refresh.svg" alt="refresh icon" onClick={this.onRefresh} />
            </div> : null}
        <div className={`input-group-append${!fieldProps.isLast ? ' top-bottom-right-border' : ''}`}>
          {!fieldProps.isLast && (
            <span className="input-group-text">
              <i className="icon icon-close" onClick={this.onRemove} />
            </span>
          )}
          {fieldProps.isLast && (
            <button
              className="btn btn-outline-secondary"
              type="button"
              onClick={this.props.onAddQuery}
            >
              {i18n.t("manage.queryVariations.addQueryVariation")}
            </button>
          )}
        </div>
      </div>
    );
  }
}

const QueryVariations: any = (fieldProps: QueryVariationsProps) => (
  <Fragment>
    <FieldArray
      name={fieldProps.name ? fieldProps.name : "queryVariations"}
      component={AlternateQueryFieldGroup}
      props={{ onRefresh: fieldProps.onRefresh, showWandIcon: fieldProps.showWandIcon }}
    />
  </Fragment>
);

export default QueryVariations;
