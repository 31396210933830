import { withOktaAuth } from "@okta/okta-react";
import { AuthState } from "@okta/okta-auth-js";
import * as React from "react";
import { connect } from "react-redux";
import { compose, Dispatch } from "redux";
import { validateAuthState } from "../../Auth/AuthHelper";
import i18n from "../../i18n";
import { IStoreState } from "../../Model";
import { LoadingIndicator } from "../../Shared/LoadingIndicator";
import { getUserTenantsThunk, getTenantFeatureSettingsThunk, updateTenantThunk } from "./duck/Actions";
import { ITenantSelectorStateProps } from "./duck/Model";
import { WithOktaAuthProps } from "src/ts/Auth/duck/Models";
import appHistory from "../../../ts/AppHistory";
import { Routes } from "../../../ts/AppConstants";

interface ITenantSelectorDispatchProps {
  getTenants: (auth: AuthState) => any;
  updateTenant: (tenant: string, auth: AuthState) => any;
}

interface ITenantSelecorOwnProps {
  tenants: string[];
  selectedTenant: string;
}

type TenantProps = ITenantSelectorDispatchProps &
  WithOktaAuthProps &
  ITenantSelectorStateProps &
  ITenantSelecorOwnProps;

class TenantSelector extends React.Component<TenantProps> {
  constructor(props: TenantProps) {
    super(props);
  }

  public componentDidMount() {
    validateAuthState(this.props.authState);
    this.props.getTenants(this.props.authState);
  }

  public componentDidUpdate(prevProps: TenantProps) {
    if (this.props.authState.isAuthenticated) {
      if (
        prevProps.selectedTenant !== "" &&
        prevProps.selectedTenant !== this.props.selectedTenant
      ) {
        appHistory.push(Routes.home);
        window.location.reload();
      }
    } else {
      validateAuthState(this.props.authState);
    }
  }

  public render() {
    if (this.props.updating === true) {
      return <LoadingIndicator busy={this.props.updating} message={i18n.t("base.loading.updateTenant")}/>
    }
    return (
      <div className="dropdown">
        <a
          className="dropdown-toggle"
          id="dropdownMenuButton"
          data-bs-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <i className="icon icon-version"/>
        </a>
        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
          {this.props.tenants?.map((tenant) => (
            
            <a
              className="dropdown-item"
              href="#"
              id={tenant}
              key={tenant}
              onClick={
                // tslint:disable-next-line: jsx-no-lambda
                (e) => {
                  if (tenant !== this.props.selectedTenant) {
                    return this.props.updateTenant(tenant, this.props.authState)
                  }
                }
              }
              style={{ backgroundColor: tenant === this.props.selectedTenant ? '#bec4c0' : 'white' }}
            >
              {tenant}
            </a>
          ))}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: IStoreState) => {
  return {
    ...state.tenants,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    getTenants: (auth: AuthState) => {
      dispatch(getUserTenantsThunk(auth));
    },
    updateTenant: (tenant: string, auth: AuthState) => {
      dispatch(updateTenantThunk(tenant, auth));
      dispatch(getTenantFeatureSettingsThunk(tenant, auth));
    },
  };
};

const hoc = compose(
  withOktaAuth,
  connect<
    ITenantSelectorStateProps,
    ITenantSelectorDispatchProps,
    ITenantSelecorOwnProps
  >(mapStateToProps, mapDispatchToProps)
)(TenantSelector);

export { hoc as TenantSelector };
