import update from "immutability-helper";
import { TenantAction, TenantActionTypes } from "./Actions";
import { ITenantSelectorStateProps } from "./Model";

const initialState: ITenantSelectorStateProps = {
  fetching: false,
  fetched: false,
  fetchFailed: false,
  updating: false,
  updated: false,
  updateFailed: false,
  fetchedFeatureSettings: false,
  fetchingFeatureSettings: false,
  fetchFeatureSettingsFailed: false,
  error: "",
  tenants: [],
  selectedTenant: "",
  featureSettings: { enableAIAssistant: false, enableExternalDataSources: false, enableArtefactListingView: false }
};

export const TenantReducer = (
  state = initialState,
  action: TenantAction
): ITenantSelectorStateProps => {
  switch (action.type) {
    case TenantActionTypes.FetchedTenantsPending:
      return update(state, {
        fetched: { $set: false },
        fetching: { $set: true },
        fetchFailed: { $set: false },
        error: { $set: "" },
      });
    case TenantActionTypes.FetchedTenantsRejected:
      return update(state, {
        tenants: { $set: initialState.tenants },
        selectedTenant: { $set: "" },
        error: { $set: action.payload.error },
        fetched: { $set: false },
        fetching: { $set: false },
        fetchFailed: { $set: true },
      });
    case TenantActionTypes.FetchedTenants:
      return {
        ...state,
        fetched: true,
        fetching: false,
        fetchFailed: false,
        tenants: action.payload.tenants,
        error: '',
        selectedTenant: action.payload.currentTenant
      }
    case TenantActionTypes.UpdateTenantPending:
      return update(state, {
        updated: { $set: false },
        updating: { $set: true },
        updateFailed: { $set: false },
        error: { $set: "" },
      });
    case TenantActionTypes.UpdatedTenant:
      return update(state, {
        updated: { $set: true },
        updating: { $set: false },
        updateFailed: { $set: false },
        error: { $set: "" },
        selectedTenant: { $set: action.payload.tenant },
      });
    case TenantActionTypes.UpdateTenantRejected:
      return update(state, {
        error: { $set: action.payload.error },
        updating: { $set: false },
        updated: { $set: false },
        updateFailed: { $set: true },
      });
    case TenantActionTypes.FetchedTenantFeatureSettingsPending:
      return update(state, {
        fetchingFeatureSettings: { $set: true },
        fetchedFeatureSettings: { $set: false },
        fetchFeatureSettingsFailed: { $set: false },
        error: { $set: "" },
      });
    case TenantActionTypes.FetchedTenantFeatureSettings:
      return update(state, {
        fetchingFeatureSettings: { $set: false },
        fetchedFeatureSettings: { $set: true },
        fetchFeatureSettingsFailed: { $set: false },
        error: { $set: "" },
        featureSettings: {
          $set: action.payload.featureSettings ? action.payload.featureSettings : { enableAIAssistant: false, enableExternalDataSources: false, enableArtefactListingView: false }
        },
      });
    case TenantActionTypes.FetchedTenantFeatureSettingsRejected:
      return update(state, {
        error: { $set: action.payload.error },
        fetchingFeatureSettings: { $set: false },
        fetchedFeatureSettings: { $set: false },
        fetchFeatureSettingsFailed: { $set: true },
      });
    default:
      return state;
  }
};
