import { AuthState } from '@okta/okta-auth-js';
import { Server } from '../../Shared/Util/Server';

export function searchLinkedResponses(docId: number, pageNumber: number, itemsPerPage: number, sortOrder: string, auth: AuthState): Promise<Api.IResponseSearchResponse|null> {
  let url = `/response/search?documentId=${docId}&pageNumber=${pageNumber}&resultsPerPage=${itemsPerPage}`;
  if(sortOrder) {
    url = `${url}&sortOrder=${sortOrder}`;
  }
  return Server.get<Api.IResponseSearchResponse>(url, auth);
}

export function searchLinkedResponsesForExternalSources(selectedTenant: string, query: string, pageNumber: number, itemsPerPage: number, auth: AuthState): Promise<Api.IExternalSourcesSearchResponse | null> {
  let url = `/responses/${selectedTenant}?primaryQuery=${query}&pageNumber=${pageNumber}&pageSize=${itemsPerPage}`;

  return Server.get<Api.IExternalSourcesSearchResponse>(url, auth);
}
