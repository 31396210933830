import { WrappedFieldProps } from "redux-form";
import i18n from "../i18n";

export interface IPanvivaFieldProps extends React.InputHTMLAttributes<HTMLInputElement>, WrappedFieldProps {
  label: string;
  required: boolean;
  tooltipText: string;
  badgeText: string;
}

export const requiredRule = (value: any) => {
  return value ? undefined : i18n.t("forms.requiredValidationMessage");
};

export const arrayRequiredRule = (values: []) => {
  return (values && values.length) ? undefined : i18n.t("forms.requiredArrayValidationMessage");
};
