import { withOktaAuth } from "@okta/okta-react";
import * as React from "react";
import i18n from '../../i18n';
import './Logout.scss';
import { WithOktaAuthProps } from "../duck/Models";

export default withOktaAuth(class Logout extends React.Component<WithOktaAuthProps> {
  constructor(props: WithOktaAuthProps) {
    super(props);
      this.props.oktaAuth.authStateManager.updateAuthState();
  }

  public render() {
    return (
      <main className="logout-main">
        <div className="logout-container">
          <h1 className="title">{i18n.t("logout.heading")}</h1>
          <p className="message">{i18n.t("logout.message")}</p>
          <p className="message">{i18n.t("logout.submessage")}</p>
        </div>
      </main>
    )
  }
});
