import i18n from '../../i18n';
import { IModalProps, Modal} from './Modal';

export interface IConfirmModalProps extends IModalProps {
  visible: boolean;
  onConfirm: (confirming: boolean) => any;
  disableButtons?: boolean;
  title?: string;
  confirmBtnText?: string;
  cancelBtnText?: string;
  allowCancel?: boolean;
}

export const ConfirmModal = (props: IConfirmModalProps) => {
  const { visible, title, confirmBtnText, cancelBtnText, onConfirm, children, disableButtons = false, allowCancel = true, ...other } = props;
  const handleOK = () => {
    onConfirm(true);
  }

  const handleCancel = () => {
    onConfirm(false);
  }


  return (
    <Modal visible={visible} {...other}>
      <div className="modal-header">
        <h5 className="modal-title">{title || i18n.t('modal.confirmation')}</h5>
      </div>
      <div className="modal-body">
        {children}
      </div>
      <div className="modal-footer">
        {allowCancel && <button type="button" className="btn btn-outline-secondary" onClick={handleCancel} disabled={disableButtons}>
          { cancelBtnText || i18n.t('modal.dialog.no') }
        </button>}
        <button type="button" className="btn btn-primary" onClick={handleOK} disabled={disableButtons}>
        { confirmBtnText || i18n.t('modal.dialog.yes') }
        </button>
      </div>
    </Modal>
  )
};
