import * as classNames from "classnames";
import * as React from "react";
import { numberOfEmptyRows } from "../../AppConstants";
import i18n from "../../i18n";
import { EmptyListModal } from "../../Shared/EmptyListModal/EmptyListModal";
import { SkeletonTableRow } from "../../Shared/SkeletonTableRow";
import { UpdateListItem } from "./UpdateListItem";

export interface IUpdatesProps {
  updates: Api.IPagedResult<Api.INotificationViewModel>;
}

class UpdateList extends React.Component<IUpdatesProps> {
  constructor(props: IUpdatesProps) {
    super(props);
  }


  public render() {
  const isSkeleton: boolean = this.props.updates.results && this.props.updates.rowCount === 0;

    return (
      <div className="updates-container">
        <div className="updates">
          <h1 className="title">{i18n.t("home.updates")}</h1>
          {this.props.updates.rowCount > 0 && <h5 className="message">{i18n.t("home.updateExistsMessage")}</h5>}
          {!this.props.updates.rowCount && <h5 className="message">{i18n.t("home.noUpdatesMessage")}</h5>}
          <div className="vertical-spacer"></div>
          <div className="update-list">
          <table className={classNames("table", {"table-hover":!isSkeleton}, "table-responsive")}>
            <thead>
              <tr>
                <th className="referenceId" scope="col">{i18n.t("update.titleIdHeading")}</th>
                <th className="title" scope="col">{i18n.t("update.titleColHeading")}</th>
                <th className="category" scope="col">{i18n.t("update.categoryColHeading")}</th>
                <th className="date" scope="col">{i18n.t("update.dateColHeading")}</th>
              </tr>
            </thead>
            <tbody>
              { this.props.updates.results && this.props.updates.results.map(item => renderListItem(item)) }
              { isSkeleton && [...Array(numberOfEmptyRows)].map((item, i) => <SkeletonTableRow numOfColumns={4} key={i} />) }
            </tbody>
          </table>
          </div>
          { isSkeleton && <EmptyListModal message={i18n.t("update.upToDateModal.bodyMessage")} title={i18n.t("update.upToDateModal.headingMessage")} /> }
        </div>
        
      </div>
    )
  }
}

const renderListItem = (item: Api.INotificationViewModel) => (
  <UpdateListItem update={item} key={item.id} />
);


export { UpdateList };
