import { useEffect, useState } from 'react';
import { Button, Card, IconButton, Popover, Typography } from '@panviva/panviva-react-ui';
import * as moment from 'moment';
import DatePicker from 'react-datepicker';
import './FilterStyles.scss';

export const DateFilter = (props: any) => {

    const { field, firstFilterValue, secondFilterValue, onFilterApply, onClear } = props;

    const [fromDate, setFromDate] = useState(firstFilterValue);
    const [toDate, setToDate] = useState(secondFilterValue);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [open, setOpen] = useState(false);
    const id = open ? 'popper-dropdown' : undefined;

    useEffect(()=>{
        setOpen(Boolean(anchorEl))
    },[anchorEl])

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(anchorEl ? null : event.currentTarget?.parentElement);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const getDateWithoutTimeAsGmt = (value: Date, endOfDay: boolean) => {
        const inputDate = value;
        return moment(inputDate).format('YYYY-MM-DD');
    };

    const onFirstFilterChange = (value: any) => {
        const formattedValue = moment(value).format("MM-DD-YYYY");
        setFromDate(formattedValue);
    };

    const onSecondFilterChange = (value: any) => {
        const formattedValue = moment(value).format("MM-DD-YYYY");
        setToDate(formattedValue);
    };

    const onButtonClick = (buttonName: string) => {
        if (buttonName === 'apply' && (fromDate || toDate)) {
            const format_fromDate = getDateWithoutTimeAsGmt(new Date(fromDate), false);
            const format_toDate = getDateWithoutTimeAsGmt(new Date(toDate), true);
            const obj = {...fromDate && {from: {value: format_fromDate, operator: 'ge'}}, ...toDate && {to: {value: format_toDate, operator:'le'}}}
            onFilterApply(field.property, obj, "eq", true);
        } else {
            onClear(field.property); setFromDate(null); setToDate(null);
        }
        setAnchorEl(null);
    };

    return (
        <>
            <IconButton icon={(firstFilterValue || secondFilterValue) ? "filter-active" : "filter-inactive"} onClick={handleClick}/>

            <Popover id={id} open={open} anchorEl={anchorEl} className="date-filter-popover" onClose={()=>handleClose()} anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}>
                <Card style={{ display: 'flex' }}>
                    <Typography
                        className='filter-title'
                        variant="body_2"
                        >
                        {field.name}
                    </Typography>
                    <div className='date-filter-container'>
                         <DatePicker
                            selected={fromDate}
                            dateFormat="MM/dd/YYYY"
                            placeholderText="From Date"
                            maxDate={new Date()}
                            onKeyDown={(e) => e.preventDefault()}
                            onSelect={()=>event?.stopPropagation()}
                            onMonthChange={()=>event?.stopPropagation()}
                            onChange={(newValue:any) => onFirstFilterChange(newValue)} {...props}/>
                        <DatePicker
                            selected={toDate}
                            dateFormat="MM/dd/YYYY"
                            placeholderText="To Date"
                            maxDate={new Date()}
                            onKeyDown={(e) => e.preventDefault()}
                            onSelect={()=>event?.stopPropagation()}
                            onMonthChange={()=>event?.stopPropagation()}
                            onChange={(newValue:any) => onSecondFilterChange(newValue)} {...props}/>
                    </div>
                    <div className='filter-button'>                        
                        <Button variant='text' onClick={() => onButtonClick('clear')}>clear</Button>&nbsp;
                        <Button variant='text' onClick={() => onButtonClick('apply')}>apply</Button>
                    </div>
                </Card>
            </Popover>
        </>
    );
};
