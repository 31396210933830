import { NavLink } from 'react-router-dom';
import './Sidebar.scss';
import '../../../../src/styles/_icons.scss';
import { useSelector } from 'react-redux';
import { IStoreState } from 'src/ts/Model';

export const Sidebar = () => {
  const enableExternalDataSources = useSelector((state: IStoreState) => state.tenants.featureSettings.enableExternalDataSources);
  const enableDorcAnalytics = useSelector((state: IStoreState) => state.tenants.featureSettings.enableArtefactListingView);
  return <nav className="sidebar" id='Sidebar'>
    <ul>
      <li>
        <SidebarLink linkTo="/home" icon="home" linkText="Home" />
      </li>
      <li>
        <SidebarLink linkTo="/manage" icon="report" linkText="Manage" />
      </li>
      {enableExternalDataSources && <li>
        <SidebarLink linkTo="/sources" icon="document" linkText="Sources" />
      </li>}
      {enableDorcAnalytics && <li>
        <SidebarLink linkTo="/snapshot" icon="analytics" linkText="Snapshot" />
      </li>}
    </ul>
  </nav>
};

const SidebarLink = (props: { linkTo: string, linkText: string, icon: string }) => (
  <NavLink activeClassName="active" to={props.linkTo}><i className={`icon icon-${props.icon}`} />
    <span className="link-text">
      {props.linkText}
    </span>
  </NavLink>
);
