// IMPORT PACKAGE REFERENCES
import * as React from 'react';
import { Tooltip, PlacesType, VariantType } from 'react-tooltip';

export interface ITooltipProps {
  bootstrapIcon: string,
  tooltipText?: string,
  placement?: PlacesType,
  type?: VariantType,
  triggerEvent?: 'hover' | 'click',
  iconClass?: string,
  tooltipClass?: string
}

// COMPONENT

const DORCTooltip: React.FC<ITooltipProps> = (props: ITooltipProps) => {
  const tooltipID = `${props.tooltipText}-${Math.random()*1000}` 
  return (<React.Fragment>
    <span data-tooltip-id={tooltipID}
          data-tooltip-content={props.tooltipText && props.tooltipText.length > 0 ? props.tooltipText : ' '} 
          className={props.iconClass ? props.iconClass : ''}>
      <i className={`icon ${props.bootstrapIcon}`} />
    </span>
    <Tooltip id={tooltipID} place={props.placement} variant={props.type} openOnClick={props.triggerEvent === 'click'} className= {props.tooltipClass ? props.tooltipClass : "cc-tooltip"}/>
  </React.Fragment>)
}

export { DORCTooltip };
