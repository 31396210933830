import * as React from 'react';
import { sectionChangedClass } from '../../../AppConstants';
import { IOrderedSection, ISection } from '../../duck/Models';
import { DocumentDeletedNotification, SectionDeletedNotification, UntaggableDocumentNotification } from './MarkupComponents';

import * as classNames from 'classnames';
import './SectionSelector.scss';
import i18n from "../../../i18n";
import { Tooltip } from 'react-tooltip';

export interface ISectionSelectorProps {
  sections: ISection[];
  sectionDeleted: boolean;
  panvivaDocumentDeleted: boolean;
  fetchingPanvivaDocument: boolean;
  validationErrorText?: string;
  name: string;
  disabledContentCuration: boolean;
  enableAIAssistant: boolean;
  fetchContentCuration: Function;
  onSelectSections: (sections: IOrderedSection[]) => any;
  undoSections: ISection[][];
  undoSectionAction: Function;
  navigatedPageStateForSections: boolean;
}

type ISectionItemProps = { section: IOrderedSection } & { onToggleSelection: (e: any, selectedItem: IOrderedSection) => any };

export class SectionSelector extends React.Component<ISectionSelectorProps> {
  constructor(props: ISectionSelectorProps) {
    super(props);
    this.onToggleItemSelection = this.onToggleItemSelection.bind(this);
  }

  public render() {
    if (this.props.panvivaDocumentDeleted) {
      return (
        <div className="section-selector-container">
          <div className="section-selector">
            <DocumentDeletedNotification />
          </div>
        </div>
      )
    }
    if (!this.props.fetchingPanvivaDocument && this.props.sections.length <= 0) {
      return (
        <div className="section-selector-container">
          <div className="section-selector">
            <UntaggableDocumentNotification />
          </div>
        </div>
      )
    }
    return (
      <div className="section-selector-container" id={this.props.name}>
        {this.props.enableAIAssistant ?
          <>
            <span className="disclaimer-text msg-tooltip-icon">
              <i className="icon icon-info-circle margin-right-2" />
              <span className="padding-top-1">{i18n.t("manage.tooltips.documentContentDisclaimer")}</span>
            </span>
            <div className="curation-block">
              <div className="dropdown">
                <div className="">
                  {
                    this.props.undoSections.length > 1 && !this.props.navigatedPageStateForSections ?
                    <img className="EnabledUndoIcon" src="/images/undo.svg" alt="undo icon" onClick={() => this.props.undoSectionAction()} /> :
                   <img className="DisabledUndoIcon" src="/images/disabledUndo.svg" alt="undo icon" />
                  }
                  {
                    this.props.disabledContentCuration ?
                      <>
                        <div className="wandicon" data-tooltip-id="section-selector-tooltip" data-tooltip-content={i18n.t("manage.tooltips.wandiconTooltipDocumentContent")}>
                          <span className={"msg-tooltip-icon"}>
                            <button className={classNames("padding-3 font-12", "disabled-button")}
                              type="button" id="dropdownMenuButton"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <img className="greyWandicon" src="/images/greyWandicon.svg" alt="grey wand icon" />
                              AI KNOWLEDGE ASSISTANT
                              <img className="greyDownArrow" src="/images/greyDownArrow.svg" alt="grey down arrow icon" />
                            </button>
                          </span>
                        </div>
                        <Tooltip id="section-selector-tooltip" place={"right"} variant={"info"} className={"cc-tooltip"}
                          openOnClick={true} />
                      </> :
                      <button className={classNames("btn padding-3 font-12 shadow-active", "btn-outline-secondary")}
                        type="button" id="dropdownMenuButton"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <img className="enabled-wandicon" src="/images/wandicon.svg" alt="wand icon" />
                        AI KNOWLEDGE ASSISTANT
                        <img className="downArrow" src="/images/downArrow.svg" alt="down arrow icon" />
                      </button>
                  }
                  <div className="dropdown-menu btn padding-3 btn-outline-secondary font-12" aria-labelledby="dropdownMenuButton">
                    <div onClick={() => this.props.fetchContentCuration()}> Curate content</div>
                  </div>
                </div>
              </div>
            </div></> : null}

        <div className={classNames("section-selector", { "is-invalid": this.props.validationErrorText })}>
          {this.props.sectionDeleted && <SectionDeletedNotification />}
          <div className="scroll-panel">
            <ul>
              {this.props.sections.map((section, index) => (<SectionListItem section={{ ...section, index }} key={section.sectionId} onToggleSelection={this.onToggleItemSelection} />))}
            </ul>
          </div>
        </div>
        {this.props.validationErrorText && <div className="invalid-feedback">{this.props.validationErrorText}</div>}
      </div>
    );
  }

  private getSelections(sections: ISection[]): IOrderedSection[] {
    return sections.map((item, index) => ({ ...item, index })).filter((item) => item.checked);
  }

  private onToggleItemSelection(event: any, selectedItem: IOrderedSection) {
    const newSelections = this.getSelections(this.props.sections).filter(item => item.sectionId !== selectedItem.sectionId);

    if (event.target.checked) {
      newSelections.push({ ...selectedItem, checked: true });
    }

    const sortedSelections = newSelections.sort((sectionA, sectionB) => sectionA.index! - sectionB.index!);
    this.props.onSelectSections(sortedSelections);
  }
}

export class SectionListItem extends React.Component<ISectionItemProps> {
  constructor(props: ISectionItemProps) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  public render() {
    return (
      <li className={`custom-control custom-checkbox ${this.props.section.checked ? "checked" : ""}`}>
        <input className="custom-control-input" type="checkbox" value={this.props.section.sectionId} id={`section_check_${this.props.section.sectionId}`} checked={this.props.section.checked} onChange={this.onChange} />
        <label className={classNames("custom-control-label", { [sectionChangedClass]: this.props.section.hasChanges })} htmlFor={`section_check_${this.props.section.sectionId}`} dangerouslySetInnerHTML={this.getFormattedMarkup(this.props.section.richContent!)} />
      </li>
    );
  }
  private onChange(event: any) {
    this.props.onToggleSelection(event, this.props.section);
  }

  private getFormattedMarkup(rawText: string): { __html: string } {
    return {
      __html: rawText
    };
  }
}


