import {createRoot} from 'react-dom/client';
import './styles/Index';
import App from './ts/App';
import { AppLogger } from './ts/AppLogger';
import i18n from './ts/i18n';
import { LoadingIndicator } from './ts/Shared/LoadingIndicator';

const root = createRoot( document.getElementById('root') as HTMLElement);

root.render(<LoadingIndicator busy={true} />);

const config: Api.ISpaConfigurationResponse = {
  appInsightsInstrumentationKey: process.env.APP_INSIGHTS_KEY,
  oktaClientId: process.env.OKTA_CLIENT_ID,
  oktaBaseUrl: process.env.OKTA_BASE_URL
}

if (config.appInsightsInstrumentationKey === undefined || config.oktaBaseUrl === undefined || config.oktaClientId === undefined) {
  root.render(<div className="alert alert-danger" role="alert">{i18n.t("applicationBootstrap.errorMessage")}</div>);
}
else {
  AppLogger.initialise(config.appInsightsInstrumentationKey);
  root.render(<App {...config}/>);
}

