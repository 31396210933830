import { AuthState } from "@okta/okta-auth-js";
import { Action, Dispatch } from "redux";
import { fetchUpdates } from "./Operations";
import { Action as AnyAction } from "@reduxjs/toolkit";

export enum UpdateActionTypes {
  FetchUpdatesPending = "FETCH_UPDATES_PENDING",
  FetchedUpdates = "FETCHED_UPDATES",
  FetchUpdatesRejected = "FETCH_UPDATES_REJECTED"
}

export class FetchingUpdatesAction implements Action {
  public readonly type = UpdateActionTypes.FetchUpdatesPending;
}

export class FetchedUpdatesAction implements Action {
  public readonly type = UpdateActionTypes.FetchedUpdates;
  constructor(public payload: { updates: Api.IPagedResult<Api.INotificationViewModel> }) {  }
}

export class FetchUpdatesFailedAction implements Action {
  public readonly type = UpdateActionTypes.FetchUpdatesRejected;
  constructor(public payload: { error: string }) {  }
}

export type UpdateAction = FetchingUpdatesAction
  | FetchedUpdatesAction
  | FetchUpdatesFailedAction;

export const getUpdateListThunk: (pageNumber: number, itemPerPage: number, auth: AuthState) => any = (pageNumber, itemPerPage, auth) => {
  return (dispatch: Dispatch<AnyAction>) => {
    dispatch(new FetchingUpdatesAction());
    fetchUpdates(pageNumber, itemPerPage, auth)
      .then( (response:Api.INotificationsResponse) => {
          dispatch(new FetchedUpdatesAction( {updates: response.notifications} ));
      })
      .catch((errorResponse: Error) => {
        // tslint:disable-next-line:no-console
        console.error(errorResponse);
        dispatch(new FetchUpdatesFailedAction({error: errorResponse.message}));
      });
  }
}
