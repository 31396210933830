import * as classNames from "classnames";
import * as React from "react";
import i18n from "../../i18n";
import { DORCTooltip } from "../Tooltip";
import "./Search.scss";

export interface ISearchProps {
  applySearch: (keyword: string) => any;
  placeholder?: string;
  tooltip?: string;
  title?: string;
  initialValue: string;
}

interface ISearchState {
  searchKeyword: string;
  validated: boolean;
}

class Search extends React.Component<ISearchProps, ISearchState> {
  constructor(props: ISearchProps) {
    super(props);

    this.state = {
      searchKeyword: props.initialValue || "",
      validated: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleReset = this.handleReset.bind(this);
  }

  public componentDidUpdate(prevProps: ISearchProps) {
    if (prevProps && prevProps.initialValue !== this.props.initialValue) {
      this.setState({
        searchKeyword: this.props.initialValue,
      });
    }
  }

  public render() {
    return (
      <div className="search-bar">
        {this.props.title && this.props.tooltip && (
          <h5 className="title">
            {this.props.title}
            <DORCTooltip
              bootstrapIcon="icon-info-circle"
              tooltipText={this.props.tooltip}
              placement="right"
              type="info"
              iconClass="msg-tooltip-icon"
              triggerEvent="click"
              tooltipClass="cc-tooltip"
            />
          </h5>
        )}
        <form onSubmit={this.handleSubmit} noValidate={true}>
          <div className="search-row">
            <div
              className={classNames("input-group input-group-lg search-group", {
                "was-validated": this.state.validated,
              })}
            >
              <input
                type="text"
                className="form-control"
                value={this.state.searchKeyword}
                required={true}
                placeholder={this.props.placeholder}
                aria-label={this.props.placeholder}
                onChange={this.handleChange}
              />
              <button className="btn btn-primary" type="submit">
                {i18n.t("search.searchButtonText")}
              </button>
              <div className="reset-btn ms-2">
                <button
                  className="btn btn-outline-secondary btn-lg"
                  type="button"
                  onClick={this.handleReset}
                >
                  {i18n.t("search.resetButtonText")}
                </button>
              </div>
              <div className="invalid-feedback">
                {i18n.t("search.validationMessage")}
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }

  private handleChange(event: React.FormEvent<HTMLInputElement>) {
    this.setState({ searchKeyword: event.currentTarget.value });
  }

  private handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    if (this.state.searchKeyword === "") {
      this.setState({
        ...this.state,
        validated: true,
      });
    } else {
      this.props.applySearch(this.state.searchKeyword);
    }

    event.preventDefault();
  }

  private handleReset(event: React.FormEvent<HTMLButtonElement>) {
    this.setState({
      searchKeyword: "",
      validated: false,
    });
    this.props.applySearch("");
  }
}

export { Search };
