import { UserClaims  } from '@okta/okta-auth-js';
import * as React from 'react';
import { LoadingIndicator } from '../../Shared/LoadingIndicator';
//import { Footer } from "../Footer/Footer";
import { Header } from "../Header/Header";
import { Sidebar } from '../Sidebar/Sidebar';
import { WithOktaAuthProps } from 'src/ts/Auth/duck/Models';
import { withOktaAuth } from '@okta/okta-react';


interface IPageLayoutState {
  user: UserClaims | null
}

class PageLayout extends React.Component<React.PropsWithChildren<WithOktaAuthProps>, IPageLayoutState> {
  constructor(props: WithOktaAuthProps) {
    super(props);
    this.state = {
      user: null
    }
    this.handleLogout = this.handleLogout.bind(this);
    this.getUser = this.getUser.bind(this);
  }

  public async componentDidMount(){
    if (this.state.user === null) {
      await this.getUser();
    }
  }

  public async handleLogout(e: Event) {
    e.preventDefault();
    await this.props.oktaAuth.signOut({
      clearTokensBeforeRedirect: true,
      postLogoutRedirectUri: `${window.location.origin}/logout`
    });
  }

  public render() {
    if (!this.props.authState) {
      return <LoadingIndicator busy={true} />
    }
    if (this.props.authState.error) {
      throw this.props.authState.error;
    }
    return (
      <React.Fragment>
        <div className="container-fluid page-wrapper">
          <Header {...this.props}/>
          <div className="main-body container-fluid">
            <Sidebar />
            {this.props.children}
          </div>
          {/* <Footer /> */}
        </div>
      </React.Fragment>
    );
  }

  private async getUser() {
    const oktaUser = this.props.authState?.isAuthenticated
      ? await this.props.oktaAuth.getUser()
      : undefined;
    return oktaUser || null;
  }
};

export default withOktaAuth(PageLayout);
