import * as i18n from 'i18next';
import translations from '../locales/en/translation.json';

i18n
  .init({
    interpolation: {
      // React already does escaping
      escapeValue: false,
    },
    lng: 'en',
    resources: {
      en: {
        translation: translations
      }
    }
  });

export default i18n
