import { FunctionComponent } from "react";
import { useDispatch } from 'react-redux';
import './AnalyticsPage.scss';
import i18n from "../../../ts/i18n";
import { getAnalyticsCSVThunk, getAnalyticsThunk } from "../duck/Actions";
import { WithOktaAuthProps } from "src/ts/Auth/duck/Models";
import AnalyticsTable  from "../components/AnalyticsTable/AnalyticsTable";
import { fetchAnalyticsFacet } from "../duck/Operations";

export interface IAnalyticsRequestParams {
  pageNumber: number;
  pageSize: number;
  skip: number;
  filter: any;
  orderBy: string
}

export const AnalyticsPage: FunctionComponent<WithOktaAuthProps> = (props: WithOktaAuthProps) => {

  const dispatch = useDispatch();

  const getTableData = (requestParams: IAnalyticsRequestParams) => {
    dispatch(getAnalyticsThunk(props.authState, requestParams))
  }

  const getFacetsData = (propertyName: string) =>{
    return fetchAnalyticsFacet(props.authState, propertyName)
      .then((response: Api.IAnalyticsResponse) => {
        return response;
      })
      .catch((errorResponse: any) => {
        console.log(errorResponse);
      });
  }

  const handleRequestChange = (requestParams: IAnalyticsRequestParams) => {
    getTableData(requestParams);
  }

  const handleExport = async(requestParams: IAnalyticsRequestParams, columnList: string) => {
    dispatch(getAnalyticsCSVThunk(props.authState, requestParams, columnList));
  }

  return (
    <div className="analytics-container">
      <h3 className="analytics-title">{i18n.t("analytics.heading")}</h3>
      <div className="analytics-page">
        <AnalyticsTable onRequestChange={handleRequestChange} onClickExport={handleExport} onFacetChange={getFacetsData}/>
      </div>
    </div>);
}
