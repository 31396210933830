import * as React from 'react';
import { AppLogger } from './AppLogger';
import { ErrorPage } from './Error/ErrorPage';
export class ErrorBoundary extends React.Component<React.PropsWithChildren<{}>, { hasError: boolean }> {
  constructor(props: {}) {
    super(props);
    this.state = { hasError: false };
  }

  public componentDidCatch(error: any, info: any) {
    // Display fallback UI
    this.setState({ hasError: true });

    // tslint:disable-next-line:no-console
    console.error(error);
    // tslint:disable-next-line:no-console
    console.error(info)

    AppLogger.logException(error);
    AppLogger.logTrace(info);
  }

  public render() {
    if (this.state.hasError) {
      return <ErrorPage />
    }
    return this.props.children;
  }
}
