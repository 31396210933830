import { FunctionComponent, useEffect, useState } from "react";
import { useDispatch } from 'react-redux';
import SourceSearch from "../components/SourceSearch/SourceSearch";
import SourceTable from "../components/SourceTable/SourceTable";
import './SourcesPage.scss';
import i18n from "../../../ts/i18n";
import { getSourcesThunk, getSearchSourcesThunk } from "../duck/Actions";
import { WithOktaAuthProps } from "src/ts/Auth/duck/Models";

export interface ISourceRequestParams{
    pageNumber: number;
    pageSize: number;
}
 
export const SourcesPage: FunctionComponent<WithOktaAuthProps> = (props: WithOktaAuthProps) => {

    const dispatch = useDispatch();
    const [searchTextState, setSearchTextState] = useState<string>('');

    useEffect(()=>{
        getSourcesData({pageNumber: 1, pageSize: 10});
    },[])

    const getSourcesData = (requestParams: ISourceRequestParams) =>{
        dispatch(getSourcesThunk(props.authState, requestParams))
    }

    const handlePagination = (requestParams: ISourceRequestParams) =>{
        if(searchTextState){
            dispatch(getSearchSourcesThunk(props.authState, searchTextState, requestParams));  
        }else{
            getSourcesData(requestParams);
        }
    }
    
    const handleSubmit = (text:string) => {
        setSearchTextState(text);
        dispatch(getSearchSourcesThunk(props.authState, text, {pageNumber: 1, pageSize: 10}));
    }

    const handleBackClick = (requestParams: ISourceRequestParams) =>{
        setSearchTextState('');
        getSourcesData(requestParams);
    }

    return (
        <div className="sources-container">
            <h3 className="sources-title">{i18n.t("sources.heading")}</h3>
            <div className="sources-page">
                <SourceSearch onSubmit={handleSubmit} onBackClick={handleBackClick} searchTextState={searchTextState}/>
                <SourceTable onPaginationChange={handlePagination} searchTextState={searchTextState}/>
            </div>
        </div>);
}