import * as classNames from 'classnames';
import * as React from 'react';
import { IPanvivaFieldProps } from "../Forms";
import { DORCTooltip } from '../Tooltip';

class FormSelect extends React.Component<IPanvivaFieldProps> {
  constructor(props: IPanvivaFieldProps) {
    super(props);

    this.handleSelectChange = this.handleSelectChange.bind(this);
  }

  public render() {
    return (
      <div className={
        classNames(
          "form-group",
          { "required": this.props.required }
        )
      }>
        <div className="field-label">
          <h5>{this.props.label}</h5>
          <DORCTooltip bootstrapIcon="icon-info-circle" tooltipText={this.props.tooltipText} placement="right" type="info"
                           iconClass="msg-tooltip-icon" triggerEvent="click" tooltipClass="cc-tooltip"/>
        </div>
        <select name={this.props.input.name} className={classNames('form-control', 'form-select', { 'is-invalid': this.props.meta.error })} onChange={this.handleSelectChange} value={this.props.input.value}>
          {this.props.children}
        </select>
        <div className="invalid-feedback">
            { this.props.meta.error }
        </div>
    </div>
    );
  }

  private handleSelectChange(e: any) {
    this.props.input.onChange(e.currentTarget.value);
  }
}

export { FormSelect }
