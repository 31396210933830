'use strict';

// Set and Map are required by React. Prefer these implementations for IE.
// https://reactjs.org/docs/javascript-environment-requirements.html
require('core-js/es/map');
require('core-js/es/set');

if (typeof Promise === 'undefined') {
  // Rejection tracking prevents a common issue where React gets into an
  // inconsistent state due to an error, but it gets swallowed by a Promise,
  // and the user has no idea what causes React's erratic future behavior.
  require('promise/lib/rejection-tracking').enable();
  window.Promise = require('promise/lib/es6-extensions.js');
}

// fetch() polyfill for making API calls.
require('whatwg-fetch');

// Object.assign() is commonly used with React.
// It will use the native implementation if it's present and isn't buggy.
Object.assign = require('object-assign');

// StartsWith
require('./polyfills/startsWith');

// Array Find and FindIndex
require('./polyfills/findIndex');
require('core-js/actual/array/find');
