import update from "immutability-helper";
import { UpdateAction, UpdateActionTypes } from "./Actions";
import { IUpdateState } from "./Models";

const initialState: IUpdateState = {
  failed: false,
  fetched: false,
  fetching: false,
  error: '',
  updates: {
    firstRowOnPage: 0,
    lastRowOnPage: 10,
    pageCount: 0,
    pageSize: 10,
    rowCount: 0,
    currentPage: 1,
    sortOrder: '',
    searchKeyword: '',
    results: []
  },
};

export const UpdateReducer = (
  state = initialState,
  action: UpdateAction
): IUpdateState => {
  switch (action.type) {
    case UpdateActionTypes.FetchUpdatesPending:
      return update(state, {
        fetched: { $set: false },
        fetching: { $set: true },
        failed: { $set: false },
        error: { $set: '' }
      });
    case UpdateActionTypes.FetchUpdatesRejected:
      return update(state, {
        updates: { $set: { ...initialState.updates } },
        error: { $set: action.payload.error },
        fetched: { $set: false },
        fetching: { $set: false },
        failed: { $set: true }
      });
    case UpdateActionTypes.FetchedUpdates:
      return update(state, {
        fetched: { $set: true },
        fetching: { $set: false },
        failed: { $set: false },
        error: { $set: '' },
        updates: { $set: { ...action.payload.updates } }
      });
    default:
      return state;
  }
}
