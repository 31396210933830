import * as moment from 'moment';

export class DateTimeFormatter {

    public static ToLongDate(date?: Date) {
        return date ? moment(date).format("Do MMMM YYYY") : '';
    }
    public static ToLongDateTime(date?: Date) {
        return date ? moment(date).format("Do MMMM YYYY, HH:mm") : '';
    }
    public static ToShortDate(date?: Date) {
        return date ? moment(date).format("DD/MM/YYYY") : '';
    }
    public static ToShortDateTime(date?: Date) {
        return date ? moment(date).format("DD/MM/YYYY, HH:mm") : '';
    }
}
