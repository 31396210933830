import update from "immutability-helper";
import { SourcesAction, SourcesActionTypes } from "./Actions";
import { ISourcesState } from "./Models";
import i18n from "../../../ts/i18n";

const initialState: ISourcesState = {
  failed: false,
  fetched: false,
  fetching: false,
  error: '',
  data: [],
  metadata: null
};

export const SourcesReducer = (
  state = initialState,
  action: SourcesAction
): ISourcesState => {
  switch (action.type) {
    case SourcesActionTypes.FetchSourcesPending:
      return update(state, {
        fetched: { $set: false },
        fetching: { $set: true },
        failed: { $set: false },
        error: { $set: '' }
      });
    case SourcesActionTypes.FetchSourcesRejected:
      return update(state, {
        data: { $set: [] },
        metadata: { $set: null },
        error: { $set: i18n.t("sources.serverError") },
        fetched: { $set: false },
        fetching: { $set: false },
        failed: { $set: true }
      });
    case SourcesActionTypes.FetchSourcesSuccess:
      let errorMessage = "";
      if(action.payload.sources.data?.length <= 0){
        errorMessage = action.payload.operationType === "list" ? i18n.t("sources.listEmpty") : i18n.t("sources.searchEmpty");
      }
      return update(state, {
        fetched: { $set: true },
        fetching: { $set: false },
        failed: { $set: false },
        error: { $set: errorMessage },
        data: { $set: action.payload.sources.data },
        metadata: { $set: action.payload.sources.metadata },
      });
    default:
      return state;
  }
};
