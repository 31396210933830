import * as React from 'react';
import { Link } from 'react-router-dom';
import appHistory from '../../AppHistory';

class PaginationLink extends React.Component<IPaginationLink> {
    public render() {
    if (this.props.isHidden) { return null; }

    const baseCss = 'page-item ';
	  const fullCss = `${baseCss}${this.props.page === this.props.currentPage ? ' active' : ''}`;

    const route = {
      ...appHistory.location,
      pathname: `${this.props.linkUrlLeftPart}/${this.props.page}`
    };

    return (
      <li key={this.props.index} className={fullCss}>
        <Link className="page-link" to={route}><strong>{this.props.page}</strong></Link>
      </li>
  )
}
}

interface IPaginationLink{
    page: number;
    index?: number;
    isHidden?: boolean;
    currentPage: number;
    linkUrlLeftPart: string;
}
export { PaginationLink };
