import { AuthState } from "@okta/okta-auth-js";
import { Action, Dispatch } from "redux";
import { Routes } from '../../AppConstants';
import appHistory from '../../AppHistory';
import i18n from "../../i18n";
import { IStoreState } from "../../Model";
import { ApiError } from "../../Shared/Util/Server";
import { ICategory, IChannel, IContentCurationResponse, IManageResponseFormValues, IMetadatum, IOrderedSection, ISection, IResponseVariationResponse, IKeywordsResponse, IQueryVariationsResponse, ISummaryResponse } from "../duck/Models";
import {
  createDraftResponse, createResponse, deleteDraftResponse, deleteResponse, editDraftResponse, editResponse, fetchChannels,
  fetchCheckForVersionMismatch, fetchContentCuration, fetchDraftResponse, fetchMetadata, fetchPanvivaDocument, fetchResponse, fetchResponseCategories,
  publishDraftResponse, saveAsDraftResponse, fetchResponseVariation, fetchKeywords, fetchQueryVariations, fetchSummary
} from "../duck/Operations";
import { Action as AnyAction } from "@reduxjs/toolkit";

export enum ManageActionTypes {
  FetchPanvivaDocumentPending = "FETCH_PANVIVADOCUMENT_PENDING",
  FetchedPanvivaDocument = "FETCHED_PANVIVADOCUMENT",
  FetchPanvivaDocumentRejected = "FETCH_PANVIVADOCUMENT_REJECTED",
  InitialisePage = "MANAGE_RESPONSE_INITIALISE_PAGE",
  FetchResponseCategoriesPending = "FETCH_RESPONSECATEGORIES_PENDING",
  FetchedResponseCategories = "FETCHED_RESPONSECATEGORIES",
  FetchResponseCategoriesRejected = "FETCH_RESPONSECATEGORIES_REJECTED",
  SavingResponse = "SAVING_RESPONSE",
  SavedResponse = "SAVED_RESPONSE",
  SaveResponseFailed = "SAVE_RESPONSE_FAILED",
  SetCopiedDocumentSections = "MANAGE_RESPONSE_SET_COPIED_SECTIONS",
  SetResponseBodyText = "MANAGE_RESPONSE_SET_RESPONSE_BODY",
  FetchResponsePending = "FETCH_RESPONSE_PENDING",
  FetchedResponse = "FETCHED_RESPONSE",
  FetchResponseRejected = "FETCH_RESPONSE_REJECTED",
  PanvivaDocumentNotFound = "PANVIVA_DOCUMENT_MISSING",
  DeletingResponse = "DELETING_RESPONSE",
  DeletedResponse = "DELETED_RESPONSE",
  DeleteResponseFailed = "DELETE_RESPONSE_FAILED",
  FetchChannelsPending = "FETCH_CHANNELS_PENDING",
  FetchedChannels = "FETCHED_CHANNELS",
  FetchChannelsFailed = "FETCH_CHANNELS_FAILED",
  FetchMetadataPending = "FETCH_METADATA_PENDING",
  FetchedMetadata = "FETCHED_METADATA",
  FetchMetadataFailed = "FETCH_METADATA_FAILED",
  DismissError = "DISMISS_ERROR",
  SavingDraftResponse = "SAVING_DRAFT_RESPONSE",
  SavedDraftResponse = "SAVED_DRAFT_RESPONSE",
  SaveDraftResponseFailed = "SAVE_DRAFT_RESPONSE_FAILED",
  DeletingDraftResponse = "DELETING_DRAFT_RESPONSE",
  DeletedDraftResponse = "DELETED_DRAFT_RESPONSE",
  DeleteDraftResponseFailed = "DELETE_DRAFT_RESPONSE_FAILED",
  CheckingVersionMismatch = "CHECKING_FOR_VERSION_MISMATCH",
  CheckedVersionMismatch = "CHECKED_FOR_VERSION_MISMATCH",
  CheckVersionMismatchFailed = "CHECKING_FOR_VERSION_MISMATCH_FAILED",
  FetchContentCurationPending = "FETCH_CONTENT_CURATION_PENDING",
  FetchedContentCuration = "FETCHED_CONTENT_CURATION",
  FetchContentCurationFailed = "FETCH_CONTENT_CURATION_FAILED",
  SetPanvivaDocumentSections = "SET_PANVIVA_DOCUMENT_SECTIONS",
  FetchResponseVariationPending = "FETCH_RESPONSE_VARIATION_PENDING",
  FetchedResponseVariation = "FETCHED_RESPONSE_VARIATION",
  FetchResponseVariationFailed = "FETCH_RESPONSE_VARIATION_FAILED",
  ResetResponseVariation = "RESET_RESPONSE_VARIATION",
  RemoveRefreshResponseVariationIndex = "REMOVE_REFRESH_RESPONSE_VARIATION_INDEX",
  FetchKeywordsPending = "FETCH_KEYWORDS_PENDING",
  FetchedKeywords = "FETCHED_KEYWORDS",
  FetchKeywordsFailed = "FETCH_KEYWORDS_FAILED",
  FetchQueryVariationsPending = "FETCH_QUERY_VARIATIONS_PENDING",
  FetchedQueryVariations = "FETCHED_QUERY_VARIATIONS",
  FetchQueryVariationsFailed = "FETCH_QUERY_VARIATIONS_FAILED",
  FetchSummarySuccess = "FETCH_SUMMARY_SUCCESS",
  FetchSummaryPending = "FETCH_SUMMARY_PENDING",
  FetchSummaryFailed = "FETCH_SUMMARY_FAILED",
  ResetContentCuration = "RESET_CONTENT_CURATION",
  ResetKeywords = "RESET_KEYWORDS",
  ResetQueryVariations="RESET_QUERY_VARIATIONS",
  SetUndoSection = "SET_UNDO_SECTION",
  ResetDocumentSectionUndo = "RESET_DOCUMENT_SECTION_UNDO",
  SetUndoResponse = "SET_UNDO_RESPONSE",
  SetDocumentResponse = "SET_DOCUMENT_RESPONSE",
  ResetDocumentResponseUndo = "RESET_DOCUMENT_RESPONSE_UNDO"
}

export class ManageResponseInitialisePageAction implements Action {
  public readonly type = ManageActionTypes.InitialisePage;
}

export class FetchingPanvivaDocumentAction implements Action {
  public readonly type = ManageActionTypes.FetchPanvivaDocumentPending;
}

export class FetchedPanvivaDocumentAction implements Action {
  public readonly type = ManageActionTypes.FetchedPanvivaDocument;
  constructor(public payload: { document: Api.IPanvivaDocument, documentTitle: string }) { }
}

export class FetchPanvivaDocumentFailedAction implements Action {
  public readonly type = ManageActionTypes.FetchPanvivaDocumentRejected;
  constructor(public payload: { error: string }) { }
}


export class FetchingResponseCategoriesAction implements Action {
  public readonly type = ManageActionTypes.FetchResponseCategoriesPending;
}

export class FetchedResponseCategoriesAction implements Action {
  public readonly type = ManageActionTypes.FetchedResponseCategories;
  constructor(public payload: { categories: ICategory[] }) { }
}

export class FetchResponseCategoriesFailedAction implements Action {
  public readonly type = ManageActionTypes.FetchResponseCategoriesRejected;
  constructor(public payload: { error: string }) { }
}

export class SavingResponseAction implements Action {
  public readonly type = ManageActionTypes.SavingResponse;
}

export class SavedResponseAction implements Action {
  public readonly type = ManageActionTypes.SavedResponse;
  constructor(public payload: { saved: boolean }) { }
}

export class SaveResponseFailedAction implements Action {
  public readonly type = ManageActionTypes.SaveResponseFailed;
  constructor(public payload: { error: string }) { }
}


export class SetCopiedDocumentSectionsAction implements Action {
  public readonly type = ManageActionTypes.SetCopiedDocumentSections;
  constructor(public payload: { oldSections: IOrderedSection[], formValues: IManageResponseFormValues }) { }
}

export class SetResponseBodyTextAction implements Action {
  public readonly type = ManageActionTypes.SetResponseBodyText;
  constructor(public payload: { oldSections: IOrderedSection[], newSections: IOrderedSection[] }) { }
}

export class FetchingResponseAction implements Action {
  public readonly type = ManageActionTypes.FetchResponsePending;
}

export class FetchedResponseAction implements Action {
  public readonly type = ManageActionTypes.FetchedResponse;
  constructor(public payload: { responseValues: Api.IGetResponseResponse | Api.IGetDraftResponseResponse }) { }
}

export class FetchedResponseFailedAction implements Action {
  public readonly type = ManageActionTypes.FetchResponseRejected;
  constructor(public payload: { error: string }) { }
}

export class PanvivaDocumentNotFoundAction implements Action {
  public readonly type = ManageActionTypes.PanvivaDocumentNotFound;
}

export class DeletingResponseAction implements Action {
  public readonly type = ManageActionTypes.DeletingResponse;
}

export class DeletedResponseAction implements Action {
  public readonly type = ManageActionTypes.DeletedResponse;
}

export class DeletingResponseFailedAction implements Action {
  public readonly type = ManageActionTypes.DeleteResponseFailed;
  constructor(public payload: { error: string }) { }
}

export class FetchingChannelsAction implements Action {
  public readonly type = ManageActionTypes.FetchChannelsPending;
}

export class FetchedChannelsAction implements Action {
  public readonly type = ManageActionTypes.FetchedChannels;
  constructor(public payload: { channels: IChannel[] }) { }
}

export class FetchChannelsFailedAction implements Action {
  public readonly type = ManageActionTypes.FetchChannelsFailed;
  constructor(public payload: { error: string }) { }
}

export class FetchingMetadataAction implements Action {
  public readonly type = ManageActionTypes.FetchMetadataPending;
}

export class FetchedMetadataAction implements Action {
  public readonly type = ManageActionTypes.FetchedMetadata;
  constructor(public payload: { metadata: IMetadatum[] }) { }
}

export class FetchMetadataFailedAction implements Action {
  public readonly type = ManageActionTypes.FetchMetadataFailed;
  constructor(public payload: { error: string }) { }
}

export class FetchingKeywordsAction implements Action {
  public readonly type = ManageActionTypes.FetchKeywordsPending;
}

export class FetchedKeywordsAction implements Action {
  public readonly type = ManageActionTypes.FetchedKeywords;
  constructor(public payload: { keywords: string[], documentId: number, artefactId: string, formValues: IManageResponseFormValues }) { }
}

export class FetchKeywordsFailedAction implements Action {
  public readonly type = ManageActionTypes.FetchKeywordsFailed;
  constructor(public payload: { errorAIDetails: { title: string, message: string, html?: any } }) { }
}

export class FetchingQueryVariationsAction implements Action {
  public readonly type = ManageActionTypes.FetchQueryVariationsPending;
}

export class FetchedQueryVariationsAction implements Action {
  public readonly type = ManageActionTypes.FetchedQueryVariations;
  constructor(public payload: { queryVariations: string[], documentId: number, artefactId: string, numberToGenerate: number, index: number, existingVariations: string[], formValues: IManageResponseFormValues }) { }
}

export class FetchQueryVariationsFailedAction implements Action {
  public readonly type = ManageActionTypes.FetchQueryVariationsFailed;
  constructor(public payload: { errorAIDetails: { title: string, message: string, html?: any } }) { }
}

export class DismissErrorAction implements Action {
  public readonly type = ManageActionTypes.DismissError;
}

export class DeletingDraftResponseAction implements Action {
  public readonly type = ManageActionTypes.DeletingDraftResponse;
}

export class DeletedDraftResponseAction implements Action {
  public readonly type = ManageActionTypes.DeletedDraftResponse;
}

export class DeletingDraftResponseFailedAction implements Action {
  public readonly type = ManageActionTypes.DeleteDraftResponseFailed;
  constructor(public payload: { error: string }) { }
}

export class SavingDraftResponseAction implements Action {
  public readonly type = ManageActionTypes.SavingDraftResponse;
}

export class SavedDraftResponseAction implements Action {
  public readonly type = ManageActionTypes.SavedDraftResponse;
  constructor(public payload: { saved: boolean }) { }
}

export class SaveDraftResponseFailedAction implements Action {
  public readonly type = ManageActionTypes.SaveDraftResponseFailed;
  constructor(public payload: { error: string }) { }
}

export class CheckingVersionMismatchAction implements Action {
  public readonly type = ManageActionTypes.CheckingVersionMismatch;
}

export class CheckedVersionMismatchAction implements Action {
  public readonly type = ManageActionTypes.CheckedVersionMismatch;
  constructor(public payload: { versionMismatch: boolean }) { }
}

export class CheckVersionMismatchFailedAction implements Action {
  public readonly type = ManageActionTypes.CheckVersionMismatchFailed;
  constructor(public payload: { error: string }) { }
}

export class FetchingContentCurationAction implements Action {
  public readonly type = ManageActionTypes.FetchContentCurationPending;
}

export class FetchedContentCurationAction implements Action {
  public readonly type = ManageActionTypes.FetchedContentCuration;
  constructor(public payload: { taggedSections: string[], documentId: number, artefactId: string, primaryResponse: string, formValues: IManageResponseFormValues }) { }
}

export class FetchContentCurationFailedAction implements Action {
  public readonly type = ManageActionTypes.FetchContentCurationFailed;
  constructor(public payload: { errorAIDetails: { title: string, message: string, html?: any } }) { }
}

export class SetPanvivaDocumentSectionAction implements Action {
  public readonly type = ManageActionTypes.SetPanvivaDocumentSections;
  constructor(public payload: { panvivaDocumentSections: IOrderedSection[] }) { }
}

export class FetchingResponseVariationAction implements Action {
  public readonly type = ManageActionTypes.FetchResponseVariationPending;
}

export class FetchedResponseVariationAction implements Action {
  public readonly type = ManageActionTypes.FetchedResponseVariation;
  constructor(public payload: IResponseVariationResponse) { }
}

export class FetchResponseVariationFailedAction implements Action {
  public readonly type = ManageActionTypes.FetchResponseVariationFailed;
  constructor(public payload: { errorAIDetails: { title: string, message: string, html?: any } }) { }
}

export class ResetResponseVariationAction implements Action {
  public readonly type = ManageActionTypes.ResetResponseVariation;
}

export class RemoveRefreshResponseVariationIndexAction implements Action {
  public readonly type = ManageActionTypes.RemoveRefreshResponseVariationIndex;
  constructor(public payload: { index: number }) { }
}

export class FetchSummarySuccessAction implements Action {
  public readonly type = ManageActionTypes.FetchSummarySuccess;
  constructor(public payload: { primaryResponse: string, formValues: IManageResponseFormValues }) { }
}

export class FetchSummaryPendingAction implements Action {
  public readonly type = ManageActionTypes.FetchSummaryPending;
}

export class FetchSummaryFailedAction implements Action {
  public readonly type = ManageActionTypes.FetchSummaryFailed;
  constructor(public payload: { errorAIDetails: { title: string, message: string, html?: any } }) { }
}

export class ResetContentCurationAction implements Action {
  public readonly type = ManageActionTypes.ResetContentCuration;
}
export class ResetKeywordsAction implements Action {
  public readonly type = ManageActionTypes.ResetKeywords;
}

export class ResetQueryVariationsAction implements Action {
  public readonly type = ManageActionTypes.ResetQueryVariations;
}

export class SetUndoSectionAction implements Action {
  public readonly type = ManageActionTypes.SetUndoSection;
  constructor(public payload: { formValues: IManageResponseFormValues }) { }
}

export class ResetDocumentSectionUndoAction implements Action {
  public readonly type = ManageActionTypes.ResetDocumentSectionUndo;
}

export class SetUndoResponseAction implements Action {
  public readonly type = ManageActionTypes.SetUndoResponse;
  constructor(public payload: { formValues: IManageResponseFormValues }) { }
}

export class SetDocumentResponseAction implements Action {
  public readonly type = ManageActionTypes.SetDocumentResponse;
  constructor(public payload: { response: string, overrideCurrentResponse: boolean }) { }
}

export class ResetDocumentResponseUndoAction implements Action {
  public readonly type = ManageActionTypes.ResetDocumentResponseUndo;
}

export type ManageAction = FetchingPanvivaDocumentAction
  | FetchedPanvivaDocumentAction
  | FetchPanvivaDocumentFailedAction
  | ManageResponseInitialisePageAction
  | FetchingResponseCategoriesAction
  | FetchedResponseCategoriesAction
  | FetchResponseCategoriesFailedAction
  | SavingResponseAction
  | SavedResponseAction
  | SaveResponseFailedAction
  | SetCopiedDocumentSectionsAction
  | SetResponseBodyTextAction
  | FetchingResponseAction
  | FetchedResponseAction
  | FetchedResponseFailedAction
  | PanvivaDocumentNotFoundAction
  | DeletingResponseAction
  | DeletedResponseAction
  | DeletingResponseFailedAction
  | FetchingChannelsAction
  | FetchedChannelsAction
  | FetchChannelsFailedAction
  | FetchingMetadataAction
  | FetchedMetadataAction
  | FetchMetadataFailedAction
  | DismissErrorAction
  | SavingDraftResponseAction
  | SavedDraftResponseAction
  | SaveDraftResponseFailedAction
  | DeletingDraftResponseAction
  | DeletedDraftResponseAction
  | DeletingDraftResponseFailedAction
  | CheckingVersionMismatchAction
  | CheckedVersionMismatchAction
  | CheckVersionMismatchFailedAction
  | FetchContentCurationFailedAction
  | FetchedContentCurationAction
  | FetchingContentCurationAction
  | SetPanvivaDocumentSectionAction
  | FetchResponseVariationFailedAction
  | FetchedResponseVariationAction
  | FetchingResponseVariationAction
  | ResetResponseVariationAction
  | RemoveRefreshResponseVariationIndexAction
  | FetchedKeywordsAction
  | FetchKeywordsFailedAction
  | FetchingKeywordsAction
  | FetchedQueryVariationsAction
  | FetchQueryVariationsFailedAction
  | FetchingQueryVariationsAction
  | FetchSummarySuccessAction
  | FetchSummaryPendingAction
  | FetchSummaryFailedAction
  | ResetContentCurationAction
  | ResetKeywordsAction
  | ResetQueryVariationsAction
  | SetUndoSectionAction
  | ResetDocumentSectionUndoAction
  | SetUndoResponseAction
  | SetDocumentResponseAction
  | ResetDocumentResponseUndoAction

export const getPanvivaDocumentThunk: (documentId: number, auth: AuthState) => any = (documentId, auth) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch(new FetchingPanvivaDocumentAction());
    return fetchPanvivaDocument(documentId, auth)
      .then((response: Api.IGetPanvivaDocumentResponse) => {
        if (response.documentNotFound) {
          dispatch(new PanvivaDocumentNotFoundAction());
        } else {
          dispatch(new FetchedPanvivaDocumentAction({ document: response.document, documentTitle: response.documentTitle }));
        }

      })
      .catch((errorResponse: ApiError) => {
        // tslint:disable-next-line:no-console
        console.error(errorResponse);
        dispatch(new FetchPanvivaDocumentFailedAction({ error: errorResponse.message }));
      }
      );
  }
}

export const getResponseCategoriesThunk: (auth: AuthState) => any = (auth) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch(new FetchingResponseCategoriesAction());
    return fetchResponseCategories(auth)
      .then((response: Api.ICategoriesResponse) => {
        dispatch(new FetchedResponseCategoriesAction({ categories: response.categories }));
      })
      .catch((errorResponse: Error) => {
        // tslint:disable-next-line:no-console
        console.error(errorResponse);
        dispatch(new FetchResponseCategoriesFailedAction({ error: errorResponse.message }));
      });
  }
}

export const getResponseOrDraftThunk: (responseId: string, isDraft: boolean, auth: AuthState) => any = (responseId, isDraft, auth) => {
  return (dispatch: Dispatch<Action>): Promise<any> => {
    dispatch(new FetchingResponseAction());
    return (isDraft ? fetchDraftResponse(responseId, auth) : fetchResponse(responseId, auth))
      .then((response: Api.IGetDraftResponseResponse | Api.IGetResponseResponse) => {
        dispatch(new FetchedResponseAction({ responseValues: response }));
      })
      .catch((errorResponse: Error) => {
        // tslint:disable-next-line:no-console
        console.error(errorResponse);

        if (errorResponse instanceof ApiError && (errorResponse as ApiError).status === 404) {
          appHistory.push(Routes.notFound);
        } else {
          dispatch(new FetchedResponseFailedAction({ error: errorResponse.message }));
        }
      });
  }
}

export const saveResponseThunk: (values: IManageResponseFormValues, panvivaDocumentId: string, panvivaDocumentVersion: number, responseId: string, auth: AuthState, isDraft?: boolean) => any = (values, panvivaDocumentId, panvivaDocumentVersion, responseId, auth, isDraft = false) => {
  return (dispatch: Dispatch<AnyAction>) => {
    dispatch(new SavingResponseAction());

    const savingPromise: (auth: AuthState) => Promise<Api.ICreateResponseResponse | Api.IUpdateResponseResponse | Api.IPublishDraftResponseResponse | null> = () => {
      if (responseId !== '' && responseId !== '0') {
        if (isDraft) {
          return publishDraftResponse(values, responseId, panvivaDocumentVersion, auth);
        } else {
          return editResponse(values, responseId, panvivaDocumentVersion, auth);
        }
      } else {
        return createResponse(values, panvivaDocumentId, panvivaDocumentVersion, auth);
      }
    };

    return savingPromise(auth)
      .then((response: any) => {
        dispatch(new SavedResponseAction({ saved: true }));
      })
      .catch((errorResponse: Error) => {
        // tslint:disable-next-line:no-console
        console.error(errorResponse);
        if (errorResponse instanceof ApiError && errorResponse.status < 500) {
          dispatch(new SaveResponseFailedAction({ error: i18n.t("manage.dialogs.error.errorMessage") }));
        } else {
          dispatch(new SaveResponseFailedAction({ error: i18n.t("manage.dialogs.error.errorMessageRetry") }));
        }
      });
  }
}

export const copyDocumentSectionsThunk: (formValues: IManageResponseFormValues) => any = (formValues) => {
  return (dispatch: Dispatch<AnyAction>, getState: () => IStoreState) => {
    const currentlyCopiedSections = getState().manageResponse.copiedDocumentSections;
    dispatch(new SetResponseBodyTextAction({ oldSections: currentlyCopiedSections, newSections: formValues?.taggedSections }));
    dispatch(new SetCopiedDocumentSectionsAction({ oldSections: currentlyCopiedSections, formValues: formValues }));
  }
}

export const SetPanvivaDocumentSectionActionThunk: (selectedSections: IOrderedSection[]) => any = (selectedSections) => {
  return (dispatch: Dispatch<AnyAction>, getState: () => IStoreState) => {
    let parsedPanvivaDocumentSections: IOrderedSection[] = JSON.parse(JSON.stringify(getState().manageResponse.panvivaDocumentSections)) ;
    let sectionIds = selectedSections.map((x) => x.sectionId);
    let filteredPanvivaDocumentSections = parsedPanvivaDocumentSections.map((x) => {
      if (!sectionIds.includes(x.sectionId)) {
        x.checked = false;
      } else {
        x.checked = true;
      }
      return x;
    })
    dispatch(new SetPanvivaDocumentSectionAction({ panvivaDocumentSections: filteredPanvivaDocumentSections }));
  }
}

export const initialiseFormThunk: (responseId: string, documentId: number, isDraft: boolean, auth: AuthState) => any = (responseId, documentId, isDraft, auth) => {
  return (dispatch: Dispatch<AnyAction>) => {
    dispatch(new ManageResponseInitialisePageAction());

    return Promise.all([
      dispatch(getPanvivaDocumentThunk(documentId, auth)),
      dispatch(getResponseCategoriesThunk(auth)),
      dispatch(getChannelsThunk(auth)),
      dispatch(getMetadataThunk(auth))
    ]).then(() => {
      if (responseId !== '' && responseId !== '0') {
        dispatch(getResponseOrDraftThunk(responseId, isDraft, auth));
      }
    });
  }
}

export const deleteResponseThunk: (responseId: string, auth: AuthState) => any = (responseId, auth) => {
  return (dispatch: Dispatch<AnyAction>) => {
    dispatch(new DeletingResponseAction());
    return deleteResponse(responseId, auth)
      .then(() => dispatch(new DeletedResponseAction()))
      .catch((errorResponse: Error) => {
        // tslint:disable-next-line:no-console
        console.error(errorResponse);

        if (errorResponse instanceof ApiError && errorResponse.status < 500) {
          dispatch(new DeletingResponseFailedAction({ error: i18n.t("manage.dialogs.error.errorMessage") }));
        } else {
          dispatch(new DeletingResponseFailedAction({ error: i18n.t("manage.dialogs.error.errorMessageRetry") }));
        }
      });
  }
}

export const getChannelsThunk: (auth: AuthState) => any = (auth) => {
  return (dispatch: Dispatch<AnyAction>) => {
    dispatch(new FetchingChannelsAction());

    return fetchChannels(auth)
      .then((response: Api.IChannelsResponse) => {
        dispatch(new FetchedChannelsAction({ channels: response.channels }));
      })
      .catch((errorResponse: Error) => {
        // tslint:disable-next-line:no-console
        console.error(errorResponse);
        dispatch(new FetchChannelsFailedAction({ error: errorResponse.message }));
      });
  }
}

export const getMetadataThunk: (auth: AuthState) => any = (auth) => {
  return (dispatch: Dispatch<AnyAction>) => {
    dispatch(new FetchingMetadataAction());

    return fetchMetadata(auth)
      .then((response: Api.IMetadataResponse) => {
        dispatch(new FetchedMetadataAction({ metadata: response.metadata }));
      })
      .catch((errorResponse: Error) => {
        // tslint:disable-next-line:no-console
        console.error(errorResponse);
        dispatch(new FetchMetadataFailedAction({ error: errorResponse.message }));
      });
  }
}

export const saveDraftResponseThunk: (values: IManageResponseFormValues, panvivaDocumentId: string, panvivaDocumentVersion: number, responseId: string, auth: AuthState, fromPublished?: boolean) => any = (values, panvivaDocumentId, panvivaDocumentVersion, responseId, auth, fromPublished = false) => {
  return (dispatch: Dispatch<AnyAction>) => {
    dispatch(new SavingDraftResponseAction());

    const savingPromise: (auth: AuthState) => Promise<Api.ICreateResponseResponse | Api.IUpdateResponseResponse | Api.ISaveAsDraftResponseResponse | null> =
      () => (responseId !== '' && responseId !== '0')
        ? fromPublished
          ? saveAsDraftResponse(values, responseId, panvivaDocumentVersion, auth)
          : editDraftResponse(values, responseId, panvivaDocumentVersion, auth)
        : createDraftResponse(values, panvivaDocumentId, panvivaDocumentVersion, auth);

    return savingPromise(auth)
      .then((response: any) => {
        dispatch(new SavedDraftResponseAction({ saved: true }));
      })
      .catch((errorResponse: Error) => {
        // tslint:disable-next-line:no-console
        console.error(errorResponse);
        if (errorResponse instanceof ApiError && errorResponse.status < 500) {
          dispatch(new SaveDraftResponseFailedAction({ error: i18n.t("manage.dialogs.error.errorMessage") }));
        } else {
          dispatch(new SaveDraftResponseFailedAction({ error: i18n.t("manage.dialogs.error.errorMessageRetry") }));
        }
      });
  }
}

export const deleteDraftResponseThunk: (draftResponseId: string, auth: AuthState) => any = (draftResponseId, auth) => {
  return (dispatch: Dispatch<AnyAction>) => {
    dispatch(new DeletingDraftResponseAction());

    return deleteDraftResponse(draftResponseId, auth)
      .then(() => dispatch(new DeletedDraftResponseAction()))
      .catch((errorResponse: Error) => {
        // tslint:disable-next-line:no-console
        console.error(errorResponse);

        if (errorResponse instanceof ApiError && errorResponse.status < 500) {
          dispatch(new DeletingDraftResponseFailedAction({ error: i18n.t("manage.dialogs.error.errorMessage") }));
        } else {
          dispatch(new DeletingDraftResponseFailedAction({ error: i18n.t("manage.dialogs.error.errorMessageRetry") }));
        }
      });
  }
}

export const checkVersionMismatchThunk: (responseId: string, auth: AuthState) => any = (responseId, auth) => {
  return (dispatch: Dispatch<AnyAction>) => {
    dispatch(new CheckingVersionMismatchAction());

    return fetchCheckForVersionMismatch(responseId, auth)
      .then((response: Api.ICheckForVersionMismatchResponse) => dispatch(new CheckedVersionMismatchAction(response)))
      .catch((errorResponse: Error) => {
        // tslint:disable-next-line:no-console
        console.error(errorResponse);
        dispatch(new CheckVersionMismatchFailedAction({ error: i18n.t("manage.dialogs.error.errorMessageRetry") }));
      });
  }
}

export const fetchContentCurationThunk: (artefactId: string, documentId: number, primaryQuery: string, documentContent: ISection[], formValues: IManageResponseFormValues, auth: AuthState) => any = (artefactId, documentId, primaryQuery, documentContent, formValues, auth) => {
  return (dispatch: Dispatch<AnyAction>) => {
    dispatch(new FetchingContentCurationAction());

    return fetchContentCuration(artefactId, documentId, primaryQuery, documentContent, auth)
      .then((response: IContentCurationResponse) => {
        response.formValues = formValues;
        dispatch(new FetchedContentCurationAction(response))
      })
      .catch((errorResponse: Error) => {
        const errorDetails = fetchErrorDetails(errorResponse as ApiError, "contentCurationError");
        dispatch(new FetchContentCurationFailedAction({
          errorAIDetails: {
            title: errorDetails.title,
            message: errorDetails.message,
            html: errorDetails.html
          }
        }));
      });
  }
}

export const fetchResponseVariationThunk: (artefactId: string, documentId: number, channels: string[], responseContent: string, index: number, formValues: IManageResponseFormValues, auth: AuthState) => any = (artefactId, documentId, channels, responseContent, index, formValues,auth) => {
  return (dispatch: Dispatch<AnyAction>) => {
    dispatch(new FetchingResponseVariationAction());

    let existingVariation = "";
    if(formValues.responseVariations[index]){
      existingVariation = formValues.responseVariations[index].content;
    }

    return fetchResponseVariation(artefactId, documentId, channels, responseContent, existingVariation, auth)
      .then((response: IResponseVariationResponse) => {
        response.index = index;
        response.formValues = formValues;
        dispatch(new FetchedResponseVariationAction(response))
      })
      .catch((errorResponse: Error) => {
        // tslint:disable-next-line:no-console
        console.error(errorResponse);
        const errorDetails = fetchErrorDetails(errorResponse as ApiError, "responseVariationError");
        dispatch(new FetchResponseVariationFailedAction({
          errorAIDetails: {
            title: errorDetails.title,
            message: errorDetails.message,
            html: errorDetails.html
          }
        }));
      });
  }
}

export const getKeywordsThunk: (artefactId: string, documentId: number, formValues: IManageResponseFormValues, auth: AuthState) => any = (artefactId, documentId, formValues, auth) => {
  return (dispatch: Dispatch<AnyAction>) => {
    dispatch(new FetchingKeywordsAction());

    return fetchKeywords(artefactId, documentId, formValues.primaryResponse, formValues.metadata[0].values, auth)
      .then((response: IKeywordsResponse) => {
        dispatch(new FetchedKeywordsAction({ keywords: response.keywords, documentId: response.documentId, artefactId: response.artefactId, formValues: formValues }));
      })
      .catch((errorResponse: Error) => {
        // tslint:disable-next-line:no-console
        console.error(errorResponse);
        const errorDetails = fetchErrorDetails(errorResponse as ApiError, "keywordGenerationError");
        dispatch(new FetchKeywordsFailedAction({
          errorAIDetails: {
            title: errorDetails.title,
            message: errorDetails.message,
            html: errorDetails.html
          }
        }));
      });
  }
}

export const getQueryVariationsThunk: (artefactId: string, documentId: number, primaryQuery: string, numberToGenerate: number, existingVariations: string[], index: number, formValues: IManageResponseFormValues, auth: AuthState) => any = (artefactId, documentId, primaryQuery, numberToGenerate, existingVariations, index, formValues, auth) => {
  return (dispatch: Dispatch<AnyAction>) => {
    dispatch(new FetchingQueryVariationsAction());

    return fetchQueryVariations(artefactId, documentId, primaryQuery, numberToGenerate, existingVariations, auth)
      .then((response: IQueryVariationsResponse) => {
        dispatch(new FetchedQueryVariationsAction({ queryVariations: response.queryVariations, documentId: response.documentId, artefactId: response.artefactId, numberToGenerate: numberToGenerate, index: index, formValues: formValues, existingVariations: existingVariations }));
      })
      .catch((errorResponse: Error) => {
        //tslint:disable-next-line:no-console
        console.error(errorResponse);

        const errorDetails = fetchErrorDetails(errorResponse as ApiError, "queryVariationError");
        dispatch(new FetchQueryVariationsFailedAction({
          errorAIDetails: {
            title: errorDetails.title,
            message: errorDetails.message,
            html: errorDetails.html
          }
        }));
      });
  }
}

export const fetchSummaryThunk: (artefactId: string, documentId: number, primaryResponse: string, formValues: IManageResponseFormValues, auth: AuthState) => any = (artefactId, documentId, primaryResponse, formValues, auth) => {
  return (dispatch: Dispatch<AnyAction>) => {
    if (primaryResponse) {
      return dispatch(new FetchSummarySuccessAction({ primaryResponse: primaryResponse, formValues: formValues }));
    }
    else {
      dispatch(new FetchSummaryPendingAction());
      return fetchSummary(artefactId, documentId, formValues.primaryResponse, auth)
        .then((response: ISummaryResponse) => {
          dispatch(new FetchSummarySuccessAction({ primaryResponse: response.primaryResponse, formValues: formValues }));
        })
        .catch((errorResponse: ApiError) => {
          const errorDetails = fetchErrorDetails(errorResponse as ApiError, "summarizeResponseError");
          dispatch(new FetchSummaryFailedAction({
            errorAIDetails: {
              title: errorDetails.title,
              message: errorDetails.message,
              html: errorDetails.html
            }
          }));
        });
    }
  }
}

export const fetchErrorDetails = (error: ApiError, operationName: string) => {
  html: 
  switch (error.status) {
    case 400:
      return { title: i18n.t(`manage.dialogs.error.titles.${operationName}`), message: error.message }
    case 413:
      return { title: i18n.t(`manage.dialogs.error.titles.${operationName}`), 
               message: "",
               html: i18n.t(`manage.dialogs.error.${error.status}.html`, {returnObjects: true}) 
             }
    case 422:
      case 504:
      return { title: i18n.t(`manage.dialogs.error.titles.${operationName}`), message: i18n.t(`manage.dialogs.error.${error.status}`) }
    default:
      return { title: i18n.t("manage.dialogs.error.serverErrorTitle"), message: i18n.t("manage.dialogs.error.serverErrorMsg") }
  }
}
